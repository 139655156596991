import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import Select from 'react-select';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AddBrandProps, AddBrandState } from '../../interface/brand';
import { secretKey } from '../../../secret';
import { logoutUser } from '../../../redux';
import PageTitle from '../../layouts/PageTitle';
import { Button, Card, Nav, Tab } from 'react-bootstrap';
toast.configure();
class AddBrand extends Component<AddBrandProps, AddBrandState> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedTab: "one",
            company_name:"",
            organisation_number:"",
            brand_name:"",
            contact_person:"",
            email:"",
            phone_number:"",
            street_address:"",
            city:"",
            country:"",
            postal_code:"",
            admin:{},
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        
        document.title = "Add Brand"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    
    // handle click event of the Time Radio buttons
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    
    isMenuItemReady = () => {
    }
   
    // blockSpecialCharacters = () => {
    //     var checkString = this.state.itemname;
    //     if (checkString != "") {
    //         if (/[%]/.test(checkString)) {
    //             alert("Your item name has '%' sign. \nIt is not allowed.\nPlease remove it and try again.");
    //             return (false);
    //         } else {
    //             return true
    //         }
    //     }
    // }
    handleSaveBtnClick = (event: any) => {
    //     let { itemname, group, type,is_featured, priority, mode, hero_item, hero_image, hero_mobile_image, settime, itemstart, itemclose, is_lsm, storesJson, metaTitle, metaDesc,allergens } = this.state;
    //     const data: any = new FormData();
    //     let itemNamevalid = this.blockSpecialCharacters();
    //     if (itemNamevalid) {
    //         if (hero_image) {
    //             var file = hero_image[0];
    //             var newFileName = file.name.split(".")[0] + "_hero_image." + file.name.split(".")[1];
    //             data.append('files', file, newFileName);
    //         }
    //         if (hero_mobile_image) {
    //             var file = hero_mobile_image[0];
    //             var newFileName = file.name.split(".")[0] + "_hero_mobile_image." + file.name.split(".")[1];
    //             data.append('files', file, newFileName);
    //         }
    //         data.append('item_name', itemname)
    //         // if (erpid !== null) {
    //         //     data.append('erp_id', erpid)
    //         // }
    //         // if (poscode !== null) {
    //         //     data.append('pos_code', poscode)
    //         // }
    //         if (hero_item === true) {
    //             data.append('hero_item', 1)
    //         }
    //         if (is_lsm == '0') {
    //             data.append('is_lsm', 0)
    //         } else if (is_lsm == '1') {
    //             data.append('is_lsm', 1)
    //             data.append('stores_json', JSON.stringify(storesJson))
    //         }
    //         data.append('item_group_id', group)
    //         data.append('item_mode', JSON.stringify(mode))
    //         data.append('item_type', type)
    //         data.append('priority', priority)
    //         data.append('meta_title', metaTitle)
    //         data.append('meta_description', metaDesc)
    //         data.append("is_featured",is_featured ? 1 : 0)
    //         data.append('allergens',JSON.stringify(allergens))
    //     }
    }
    getTabIndex = (tabKey: any) => {
        const tabs = ["one", "two", "three"];
        return tabs.indexOf(tabKey);
    };
    getTabKey = (index: any) => {
        const tabs = ["one", "two", "three"];
        return tabs[index];
    };
    handleTabNext = () => {
        const tabCount = 3; // Total number of tabs
        const currentIndex = this.getTabIndex(this.state.selectedTab);
        const nextIndex = (currentIndex + 1) % tabCount;
        const nextTab = this.getTabKey(nextIndex);
        this.setState({ selectedTab: nextTab });
    };
    handleTabBack = () => {
        const tabCount = 3; // Total number of tabs
        const currentIndex = this.getTabIndex(this.state.selectedTab);
        const prevIndex = (currentIndex + tabCount - 1) % tabCount;
        const prevTab = this.getTabKey(prevIndex);
        this.setState({ selectedTab: prevTab });
      };
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isInserted, message } = this.props;
        if (!isInserted) {
            msg = message;
            messcolor = 'red'
        }
        return (
            <Fragment>
            {/* <PageTitle activeMenu='Add Brand'/> */}
            <Card>
            <Card.Header>
              <Card.Title>Add Brand</Card.Title>
            </Card.Header>
            <Card.Body>
                <div className="card-body">
                    <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                        {msg}
                    </p></strong>
                    <Tab.Container activeKey={`${this.state.selectedTab}`}>
                    <Nav as="ul" className="nav-pills mb-4 light">
                        <Nav.Item
                          as="li"
                          key={1}
                          onClick={() => this.setState({ selectedTab: "one" })}
                        >
                          <Nav.Link eventKey={"one"}>Company Information</Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          key={2}
                          onClick={() => this.setState({ selectedTab: "two" })}
                        >
                          <Nav.Link eventKey={"two"}>Admin Registration</Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          key={3}
                          onClick={() =>
                            this.setState({ selectedTab: "three" })
                          }
                        >
                          <Nav.Link eventKey={"three"}>Add</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content className="pt-4">
                        <Tab.Pane eventKey={"one"} key={1}>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="form-control-label">Company Name <span className="text-danger">*</span></label>
                                    <input id="itemname" type="text" name="company_name" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className='col-1'>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="form-control-label">Organisation number <span className="text-danger">*</span></label>
                                    <input id="itemname" type="text" name="organisation_number" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="form-control-label">Brand Name <span className="text-danger">*</span></label>
                                    <input id="itemname" type="text" name="brand_name" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className='col-1'>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="form-control-label">Contact Person <span className="text-danger">*</span></label>
                                    <input id="itemname" type="text" name="contact_person" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="form-control-label">Email <span className="text-danger">*</span></label>
                                    <input id="itemname" type="text" name="email" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className='col-1'>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="form-control-label">Phone number <span className="text-danger">*</span></label>
                                    <input id="itemname" type="text" name="phone_number" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="form-control-label">Street Address <span className="text-danger">*</span></label>
                                    <input id="itemname" type="text" name="street_address" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className='col-1'>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="form-control-label">City <span className="text-danger">*</span></label>
                                    <input id="itemname" type="text" name="city" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="form-control-label">Country <span className="text-danger">*</span></label>
                                    <input id="itemname" type="text" name="country" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className='col-1'>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="form-control-label">Postal Code <span className="text-danger">*</span></label>
                                    <input id="itemname" type="text" name="postal_code" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={"two"} key={2}>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="form-control-label">Admin name <span className="text-danger">*</span></label>
                                    <input id="itemname" type="text" name="admin_name" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className='col-1'>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="form-control-label">Last name <span className="text-danger">*</span></label>
                                    <input id="itemname" type="text" name="last_name" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="form-control-label">Admin email <span className="text-danger">*</span></label>
                                    <input id="itemname" type="text" name="admin_email" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className='col-1'>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="form-control-label">Phone number <span className="text-danger">*</span></label>
                                    <input id="itemname" type="text" name="admin_phone" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="form-control-label">Password <span className="text-danger">*</span></label>
                                    <input id="itemname" type="text" name="password" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className='col-1'>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="form-control-label">Confirm Password <span className="text-danger">*</span></label>
                                    <input id="itemname" type="text" name="confirm_password" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={"three"} key={3} style={{marginLeft:"18px"}}>
                        <section>
                        <div className="row" style={{position:"relative",top:"-15px"}}>
                            <div className="col">
                                <div className="form-group">
                                    <label className="form-control-label"><strong>Company Information</strong> <span className="btn btn-primary edit-on-add ml-4">Edit</span></label>
                                    {/* <input id="itemname" type="text" name="itemname" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} /> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-control-label">Company name</label>
                                    <input id="itemname" disabled={true} type="text" name="company_name" value={this.state.company_name} required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-control-label">Organisation number</label>
                                    <input id="itemname" disabled={true} type="text" name="organisation_number" value={this.state.organisation_number} required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-control-label">Brand name</label>
                                    <input id="itemname" disabled={true} type="text" name="brand_name" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-control-label">Contact person</label>
                                    <input id="itemname" disabled={true} type="text" name="contact_person" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-control-label">Email</label>
                                    <input id="itemname" disabled={true} type="text" name="email" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-control-label">Phone number</label>
                                    <input id="itemname" disabled={true} type="text" name="phone_number" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-control-label">Street address</label>
                                    <input id="itemname" disabled={true} type="text" name="street_address" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-control-label">City</label>
                                    <input id="itemname" disabled={true} type="text" name="city" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-control-label">Country</label>
                                    <input id="itemname" disabled={true} type="text" name="country" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-control-label">Postal code</label>
                                    <input id="itemname" disabled={true} type="text" name="postal_code" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                        </section>
                        <section>
                        <div className="row" style={{position:"relative",top:"-15px"}}>
                            <div className="col">
                                <div className="form-group">
                                    <label className="form-control-label"><strong>Admin registration</strong> <span className="btn btn-primary edit-on-add ml-4">Edit</span></label>
                                    {/* <input id="itemname" type="text" name="itemname" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} /> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-control-label">First name</label>
                                    <input id="itemname" disabled={true} type="text" name="first_name" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-control-label">Last name</label>
                                    <input id="itemname" disabled={true} type="text" name="last_name" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-control-label">Email</label>
                                    <input id="itemname" disabled={true} type="text" name="admin_email" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-control-label">Phone number</label>
                                    <input id="itemname" disabled={true} type="text" name="phone_number" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-control-label">Password</label>
                                    <input id="itemname" disabled={true} type="text" name="password" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="form-control-label">Confirm Password</label>
                                    <input id="itemname" disabled={true} type="text" name="comfirm_password" required data-msg="Please enter Item Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                        </section>
                        </Tab.Pane>
                    </Tab.Content>
                    </Tab.Container>
                    {/* <div className="form-group d-flex justify-content-end mt-4">
                        <button className='btn btn-primary' disabled={!this.isMenuItemReady()} onClick={this.handleSaveBtnClick}>Save</button>
                    </div> */}
                </div>
            </Card.Body>
            <Card.Footer>
              <div className=''>{this.renderButtons()}</div>
            </Card.Footer>
            </Card>
            </Fragment>
        );
    }
    renderButtons() {
        const { selectedTab } = this.state;
        const isFirstTab = selectedTab === "one";
        const isLastTab = selectedTab === "three";
    
        if (isLastTab) {
            return (
              <>
                <div className='row'>
                <div className='col-6'>
                <Button
                  className='previous-button'
                  variant="light"
                  onClick={this.handleTabBack}
                  disabled={isFirstTab}
                >
                  Previous
                </Button>
                </div>
                <div className='col-6 save-and-continue' >
                <button className="btn btn-primary" onClick={this.handleSaveBtnClick}>
                  Add Brand
                </button>
                </div>
                </div>

              </>
            );
          } else {
            return (
              <>
              
              <div className='row'>
                <div className='col-6'>
                <Button
                variant='light'
                  className="previous-button"
                  onClick={this.handleTabBack}
                  disabled={isFirstTab}
                >
                  Previous
                </Button>
                </div>
                <div className='col-6 save-and-continue' > 
                <button className="btn btn-primary"  onClick={this.handleTabNext}>
                  Save & Continue
                </button>               
                </div>
                </div>
              </>
            );
          }
      }
}
const mapStateToProps = (state: any) => {
    return {
        message: state.menu.message,
        isInserted: state.menu.isInserted,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddBrand);