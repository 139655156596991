import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import jwt from "jsonwebtoken";
import { Link, Redirect } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  EditMenuItemProps,
  EditMenuItemState,
} from "../../../interface/newMenu";
import { secretKey } from "../../../../secret";
import {
  checkDuplicateErpId,
  checkDuplicatePosCode,
  editItem,
  getMenuItem,
  groupsList,
  logoutUser,
  menusList,
  statesList,
  storesListForMultiSelect,
} from "../../../../redux";
import {
  allergensList,
  OrderingModes,
} from "../../../../redux/actions/menuAction";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { Row, Col, Card, Tab, Nav, Modal, Button } from "react-bootstrap";
toast.configure();
class EditMenuItem extends Component<EditMenuItemProps, EditMenuItemState> {
  constructor(props: any) {
    super(props);
    this.state = {
      itemname: "",
      erpid: "",
      poscode: "",
      group: "",
      type: "",
      itemdesc: "",
      priority: "",
      images: null,
      hero_image: null,
      hero_mobile_image: null,
      hero_item: false,
      settime: "alltime",
      is_lsm: "0",
      itemstart: "",
      itemclose: "",
      tax_percent: "",
      state: "",
      metaTitle: "",
      metaDesc: "",
      mode: [],
      storesJson: [],
      is_featured: false,
      is_alcohol: false,
      is_halal:false,
      is_veg:false,
      is_vegan:false,
      selectedTab: "one",
      sizejson: [
        {
          id: "",
          pos_code: "",
          erp_id: "",
          aloha_cat_id: "",
          size: "",
          cost: "",
          mrp: "",
          extra_price: "",
          description: "",
          alt_text: "",
          image_url: null,
          order_modes_price: [],
        },
      ],
      itemId: "",
      allergens: "",
      banner_title: "",
      banner_description: "",
    };
    this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleItemsByGroupId = this.handleItemsByGroupId.bind(this);
  }

  componentDidMount() {
    document.title = "Menu Items";
    // let id = this.props.match.params.id;
    this.props.menusList();
    this.props.groupsList();
    this.props.storesList();
    this.props.allergensList();
    this.props.OrderingModes();
  }
  // shouldComponentUpdate(nextProps:any, nextState:any) {
  //     console.log("nextState",nextState)
  //     if (nextProps.itemData.item_name === nextState.itemname) {
  //         return true;
  //     }
  //     else if(this.state.itemname !== nextState.itemname)
  //     {
  //         return true
  //     }
  //     return false;
  //   }

  handleInputChange(event: { target: { name: any; value: any } }) {
    console.log("event.target.name", event.target.name);
    console.log("event.target.value", event.target.value);
    if (event.target.value == " ") {
      event.target.value = event.target.value.replace(/\s/g, "");
    }
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleItemsByGroupId(event: { target: { name: any; value: any } }) {
    this.setState({
      group: event.target.value,
      sizejson: [
        {
          id: "",
          pos_code: "",
          erp_id: "",
          aloha_cat_id: "",
          size: "",
          cost: "",
          mrp: "",
          extra_price: "",
          description: "",
          alt_text: "",
          image_url: null,
          order_modes_price: [],
        },
      ],
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    console.log("nextProps edit menu item", nextProps.itemData);
    if (
      nextProps.duplicateItemPos == this.props.duplicateItemPos &&
      nextProps.duplicateItemErp == this.props.duplicateItemErp
    ) {
      this.setState({
        itemname: nextProps.itemData.item_name,
        is_featured: nextProps.itemData.is_featured,
        is_alcohol: nextProps.itemData.is_alcohol,
        is_halal: nextProps.itemData.is_halal,
        is_veg: nextProps.itemData.is_veg,
        is_vegan: nextProps.itemData.is_vegan,
        erpid: nextProps.itemData.erp_id == 0 ? "" : nextProps.itemData.erp_id,
        poscode:
          nextProps.itemData.pos_code == 0 ? "" : nextProps.itemData.pos_code,
        group:
          nextProps.itemData.item_group_id &&
          nextProps.itemData.item_group_id.group_id,
        type: nextProps.itemData.item_type,
        priority: nextProps.itemData.priority,
        hero_item: nextProps.itemData.hero_item == 1 ? true : false,
        // sizejson: nextProps.itemData.item_size ? JSON.parse(nextProps.itemData.item_size) : [],
        storesJson: nextProps.itemData.stores_json
          ? JSON.parse(nextProps.itemData.stores_json)
          : this.state.storesJson,
        is_lsm: nextProps.itemData.is_lsm,
        metaTitle: nextProps.itemData.meta_title
          ? nextProps.itemData.meta_title
          : "",
        metaDesc: nextProps.itemData.meta_description
          ? nextProps.itemData.meta_description
          : "",
      });
      if (nextProps.sizejson) {
        this.setState({
          sizejson:
            nextProps.sizejson.length > 0
              ? nextProps.sizejson
              : this.state.sizejson,
        });
      }
      if (
        nextProps &&
        nextProps.itemData &&
        nextProps.itemData.allergens &&
        nextProps.itemData.allergens.length > 0
      ) {
        this.setState({ allergens: nextProps.itemData.allergens });
      }
      if (nextProps && nextProps.itemData && nextProps.itemData.hero_items) {
        this.setState({
          banner_title: nextProps.itemData.hero_items.banner_title,
        });
        this.setState({
          banner_description: nextProps.itemData.hero_items.banner_description,
        });
      }
      if (nextProps.orderingModes.length > 0 && this.props.itemData) {
        let orderingModes = nextProps.orderingModes;
        let mode = nextProps.itemData.item_mode
          ? JSON.parse(nextProps.itemData.item_mode)
          : [];
        const results = orderingModes.filter(({ label: id1 }: any) =>
          mode.some(({ label: id2 }: any) => id2 === id1)
        );
        this.setState({ mode: results });
      }
      if (nextProps.itemData.item_start_time !== null) {
        this.setState({
          settime: "customtime",
          itemstart: moment(nextProps.itemData.item_start_time)
            .local()
            .format("YYYY-MM-DDTHH:mm"),
          itemclose: moment(nextProps.itemData.item_close_time)
            .local()
            .format("YYYY-MM-DDTHH:mm"),
        });
      } else {
        this.setState({
          settime: "alltime",
          itemstart: "",
          itemclose: "",
        });
      }
    }
  }

  handleTabNext = () => {
    const count = 5;
    const currentIndex = this.getTabIndex(this.state.selectedTab);
    const nextIndex = (currentIndex + 1) % count;
    const nextTab = this.getTabKey(nextIndex);
    this.setState({ selectedTab: nextTab });
  };
  handleTabBack = () => {
    const tabCount = 5; // Total number of tabs
    const currentIndex = this.getTabIndex(this.state.selectedTab);
    const prevIndex = (currentIndex + tabCount - 1) % tabCount;
    const prevTab = this.getTabKey(prevIndex);
    this.setState({ selectedTab: prevTab });
  };

  getTabIndex = (tabKey: any) => {
    const tabs = ["one", "two", "three", "four", "five"];
    return tabs.indexOf(tabKey);
  };
  getTabKey = (index: any) => {
    const tabs = ["one", "two", "three", "four", "five"];
    return tabs[index];
  };

  renderButtons() {
    const { selectedTab } = this.state;
    const isFirstTab = selectedTab === "one";
    const isLastTab = selectedTab === "five";

    if (isLastTab) {
      return (
        <>
          <Button
            variant="danger light"
            onClick={() => this.props.setEditItemModal(false)}
          >
            Cancel
          </Button>
          <button className="btn btn-primary" onClick={this.handleSaveBtnClick}>
            Edit Product
          </button>
        </>
      );
    } else {
      return (
        <>
          <Button
            variant="danger light"
            onClick={this.handleTabBack}
            disabled={isFirstTab}
          >
            Back
          </Button>
          <button className="btn btn-primary" onClick={this.handleTabNext}>
            Next
          </button>
        </>
      );
    }
  }

  handleChangeRad = (event: { target: { name: any; value: any } }) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleVariantInputChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = this.state.sizejson;
    list[index][name] = value;
    this.setState({ sizejson: list });
    if (name == "pos_code") {
      this.props.checkDuplicatePosCode(value);
    }
    if (name == "erp_id") {
      this.props.checkDuplicateErpId(value);
    }
  };

  handleVariantPriceInputChange = (e: any, index: any, sizeJsonindex: any) => {
    const { name, value } = e.target;
    const list: any = this.state.sizejson;
    list[sizeJsonindex].order_modes_price[index][name] = value;
    this.setState({ sizejson: list });
  };
  handlePricingOrderModesInputChange = (e: any, indexing: any, ind: any) => {
    if (e && e.length > 0) {
      if (e.length === this.state.sizejson[ind].order_modes_price.length + 1) {
        if (this.state.sizejson[ind].order_modes_price.length == 0) {
          let item_variant_id = this.state.sizejson[ind].id;
          let mode_id =
            e[this.state.sizejson[ind].order_modes_price.length].value;
          let mode_label =
            e[this.state.sizejson[ind].order_modes_price.length].label;
          let mrp = this.state.sizejson[ind].mrp;
          let extra_price = this.state.sizejson[ind].extra_price;
          let tempsizeJson = this.state.sizejson;
          let obj = {
            item_variant_id: item_variant_id,
            order_mode_id: mode_id,
            order_mode: mode_label,
            mrp: mrp,
            extra_price: extra_price,
            label: mode_label,
            value: mode_id,
          };
          tempsizeJson[ind].order_modes_price.push(obj);
          this.setState({ sizejson: tempsizeJson });
        } else {
          if (
            this.state.sizejson[ind].order_modes_price[
              this.state.sizejson[ind].order_modes_price.length - 1
            ].mrp > 0
          ) {
            let item_variant_id = this.state.sizejson[ind].id;
            let mode_id =
              e[this.state.sizejson[ind].order_modes_price.length].value;
            let mode_label =
              e[this.state.sizejson[ind].order_modes_price.length].label;
            let mrp = this.state.sizejson[ind].mrp;
            let extra_price = this.state.sizejson[ind].extra_price;
            let tempsizeJson = this.state.sizejson;
            let obj = {
              item_variant_id: item_variant_id,
              order_mode_id: mode_id,
              order_mode: mode_label,
              mrp: mrp,
              extra_price: extra_price,
              label: mode_label,
              value: mode_id,
            };
            tempsizeJson[ind].order_modes_price.push(obj);
            this.setState({ sizejson: tempsizeJson });
          } else {
            toast.error("Max retail price should not be 0,Please update", {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
              autoClose: 3000,
            });
          }
        }
      } else {
        let tempsizeJson = this.state.sizejson;
        let dubtempsizeJson = tempsizeJson[ind].order_modes_price.filter(
          (item: any) =>
            item.order_mode_id != indexing.removedValue.order_mode_id
        );
        tempsizeJson[ind].order_modes_price = dubtempsizeJson;
        this.setState({ sizejson: tempsizeJson });
      }
    } else {
      let tempsizeJson = this.state.sizejson;
      tempsizeJson[ind].order_modes_price = [];
      this.setState({ sizejson: tempsizeJson });
    }
  };

  // handle click event of the Remove button
  handleRemoveClick = (index: any) => {
    const list = this.state.sizejson;
    list.splice(index, 1);
    this.setState({ sizejson: list });
  };
  // handle click event of the Add button
  handleAddClick = (e: any, i: any) => {
    const list: any = this.state.sizejson;
    let { duplicateItemErp, duplicateItemPos } = this.props;
    if (list[i].order_modes_price[list[i].order_modes_price.length - 1]) {
      if (
        (list[i]["pos_code"] !== "" && duplicateItemPos == true) ||
        (list[i]["erp_id"] !== "" && duplicateItemErp == true) ||
        list[i].order_modes_price[list[i].order_modes_price.length - 1].mrp == 0
      ) {
        alert("Please fill all mandatory variant fields");
      } else if (i == "3") {
        alert("Sorry you can select only 4 variants");
      } else {
        const newlist: any = {
          id: "",
          pos_code: "",
          erp_id: "",
          size: "",
          cost: "",
          mrp: "",
          extra_price: "",
          description: "",
          image_url: null,
          order_modes_price: [],
        };
        this.state.mode &&
          this.state.mode.forEach((elem) => {
            let mode_id = elem.value;
            let mode_label = elem.label;
            let obj = {
              item_variant_id: "",
              order_mode_id: mode_id,
              mrp: 0,
              extra_price: 0,
              label: mode_label,
              value: mode_id,
            };
            newlist.order_modes_price.push(obj);
          });
        const size: any = this.state.sizejson.concat(newlist);
        this.setState({ sizejson: size });
      }
    } else {
      alert("Please select at least one ordering mode!");
    }
    e.preventDefault();
  };
  fileSelectedHandler = (e: any, index: any) => {
    const list: any = this.state.sizejson;
    if (e.target.files.length > 0) {
      list[index]["image_url"] = e.target.files;
      this.setState({ sizejson: list });
    }
  };
  heroItemImageSelectedHandler = (e: any) => {
    if (e.target.files.length > 0) {
      this.setState({ hero_image: e.target.files });
    }
  };
  heroItemMobileImageSelectedHandler = (e: any) => {
    if (e.target.files.length > 0) {
      this.setState({ hero_mobile_image: e.target.files });
    }
  };
  handleHeroItem = (event: { target: { name: any; value: any } }) => {
    const { hero_item } = this.state;
    this.setState({ hero_item: !hero_item });
  };
  checkMRPForAllModes = () => {
    let tempsizeJson = this.state.sizejson;
    let variants: any = [];
    for (let i = 0; i < tempsizeJson.length; i++) {
      let order_modes_price: any[] = tempsizeJson[i].order_modes_price;
      var filtered =
        order_modes_price &&
        order_modes_price.filter(function (el) {
          return el.mrp == 0 || el.mrp == "";
        });
      if (filtered.length > 0) {
        variants.push(tempsizeJson[i]);
      }
    }
    if (variants.length > 0) {
      return false;
    } else {
      return true;
    }
  };
  isMenuItemReady = () => {
    const {
      itemname,
      group,
      settime,
      itemstart,
      mode,
      itemclose,
      is_lsm,
      storesJson,
    } = this.state;
    let { duplicateItemPos, duplicateItemErp } = this.props;
    if (settime == "customtime") {
      if (is_lsm == "0") {
        return (
          itemname !== "" &&
          group !== "" &&
          itemstart !== "" &&
          itemclose !== "" &&
          mode.length > 0 &&
          duplicateItemPos !== true &&
          duplicateItemErp !== true &&
          this.checkMRPForAllModes()
        );
      } else if (is_lsm == "1") {
        return (
          itemname !== "" &&
          group !== "" &&
          itemstart !== "" &&
          itemclose !== "" &&
          storesJson.length > 0 &&
          mode.length > 0 &&
          duplicateItemPos !== true &&
          duplicateItemErp !== true &&
          this.checkMRPForAllModes()
        );
      }
    } else {
      if (is_lsm == "0") {
        return (
          itemname !== "" &&
          group !== "" &&
          mode.length > 0 &&
          duplicateItemPos !== true &&
          duplicateItemErp !== true &&
          this.checkMRPForAllModes()
        );
      } else if (is_lsm == "1") {
        return (
          itemname !== "" &&
          group !== "" &&
          storesJson.length > 0 &&
          mode.length > 0 &&
          duplicateItemPos !== true &&
          duplicateItemErp !== true &&
          this.checkMRPForAllModes()
        );
      }
    }
  };
  handleOrderModesInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      if (e.length < this.state.mode.length) {
        let tempsizeJson = this.state.sizejson;
        for (let i = 0; i < tempsizeJson.length; i++) {
          let tempArr: any[] = tempsizeJson[i].order_modes_price;
          let selectedItems = tempArr.filter((obj) =>
            e.find((s: any) => s.value === obj.value)
          );
          tempsizeJson[i].order_modes_price = selectedItems;
        }
        this.setState({ sizejson: tempsizeJson });
      } else {
        let tempsizeJson = this.state.sizejson;
        for (let i = 0; i < tempsizeJson.length; i++) {
          let item_variant_id = this.state.sizejson[i].id;
          let mode_id =
            e[this.state.sizejson[i].order_modes_price.length].value;
          let mode_label =
            e[this.state.sizejson[i].order_modes_price.length].label;
          let mrp = this.state.sizejson[i].mrp;
          let extra_price = this.state.sizejson[i].extra_price;
          let obj = {
            item_variant_id: item_variant_id,
            order_mode_id: mode_id,
            order_mode: mode_label,
            mrp: mrp ? mrp : 0,
            extra_price: extra_price ? extra_price : 0,
            label: mode_label,
            value: mode_id,
          };
          tempsizeJson[i].order_modes_price.push(obj);
        }
        this.setState({ sizejson: tempsizeJson });
      }
      this.setState({ mode: e });
    } else {
      this.setState({ mode: [] });
      let tempsizeJson = this.state.sizejson;
      for (let i = 0; i < tempsizeJson.length; i++) {
        tempsizeJson[i].order_modes_price = [];
      }
      this.setState({ sizejson: tempsizeJson });
    }
  };
  handleStoresInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      this.setState({ storesJson: e });
    } else {
      this.setState({ storesJson: [] });
    }
  };
  isUnique = (arr: any) => {
    const len = arr.length;
    for (let i = 0; i < len; i++) {
      for (let j = 0; j < len; j++) {
        // if the elements match, this wouldn't be a unique array
        if (
          (arr[i].pos_code !== "" && arr[j].pos_code !== "") ||
          (arr[i].erp_id !== "" && arr[j].erp_id !== "")
        ) {
          if (
            i !== j &&
            (arr[i].pos_code === arr[j].pos_code ||
              arr[i].erp_id === arr[j].erp_id)
          ) {
            return false;
          }
        }
      }
    }
    return true;
  };
  blockSpecialCharacters = () => {
    var checkString = this.state.itemname;
    if (checkString != "") {
      if (/[%]/.test(checkString)) {
        alert(
          "Your item name has '%' sign. \nIt is not allowed.\nPlease remove it and try again."
        );
        return false;
      } else {
        return true;
      }
    }
  };
  handleFeatured = (event: { target: { name: any; value: any } }) => {
    const { is_featured } = this.state;
    this.setState({ is_featured: !is_featured });
  };
  handleAlcohol = (event: { target: { name: any; value: any } }) => {
    const { is_alcohol } = this.state;
    this.setState({ is_alcohol: !is_alcohol });
  };
  handleHalal = (event: { target: { name: any; value: any } }) => {
    const { is_halal } = this.state;
    this.setState({ is_halal: !is_halal });
  };
  handleVeg = (event: { target: { name: any; value: any } }) => {
    const { is_veg } = this.state;
    this.setState({ is_veg: !is_veg });
  };
  handleVegan = (event: { target: { name: any; value: any } }) => {
    const { is_vegan } = this.state;
    this.setState({ is_vegan: !is_vegan });
  };
  handleSaveBtnClick = (event: any) => {
    let {
      itemname,
      erpid,
      priority,
      itemdesc,
      is_featured,
      is_alcohol,
      is_halal,
      is_veg,
      is_vegan,
      hero_item,
      mode,
      hero_image,
      hero_mobile_image,
      group,
      type,
      sizejson,
      settime,
      itemstart,
      itemclose,
      is_lsm,
      storesJson,
      metaTitle,
      metaDesc,
      allergens,
      banner_title,
      banner_description,
    } = this.state;
    // let id1 = this.props.match.params.id;
    let id = this.props.itemData.menu_item_id;
    const data: any = new FormData();
    let itemNamevalid = this.blockSpecialCharacters();
    if (itemNamevalid) {
      if (this.state.sizejson) {
        for (var x = 0; x < this.state.sizejson.length; x++) {
          if (this.state.sizejson[x].image_url !== null) {
            if (typeof this.state.sizejson[x].image_url === "object") {
              var file = this.state.sizejson[x].image_url[0];
              var newFileName =
                file.name.split(".")[0] +
                `_variant_${x}.` +
                file.name.split(".")[1];
              data.append("files", file, newFileName);
            }
          }
        }
      }
      const SizeJsonString = JSON.stringify(sizejson);
      if (hero_image) {
        var file = hero_image[0];
        var newFileName =
          file.name.split(".")[0] + "_hero_image." + file.name.split(".")[1];
        data.append("files", file, newFileName);
      }
      if (hero_mobile_image) {
        var file = hero_mobile_image[0];
        var newFileName =
          file.name.split(".")[0] +
          "_hero_mobile_image." +
          file.name.split(".")[1];
        data.append("files", file, newFileName);
      }
      data.append("item_name", itemname);
      if (hero_item === true) {
        data.append("hero_item", 1);
        data.append("banner_title", banner_title);
        data.append("banner_description", banner_description);
      } else {
        data.append("hero_item", 0);
      }
      data.append("item_group_id", group);
      data.append("is_featured", is_featured ? 1 : 0);
      data.append("is_alcohol", is_alcohol ? 1 : 0);
      data.append("is_halal", is_halal ? 1 : 0);
      data.append("is_veg", is_veg ? 1 : 0);
      data.append("is_vegan", is_vegan ? 1 : 0);
      data.append("item_type", type);
      data.append("item_mode", JSON.stringify(mode));
      data.append("priority", priority);
      data.append("meta_title", metaTitle);
      data.append("meta_description", metaDesc);
      data.append("allergens", JSON.stringify(allergens));
      if (settime === "customtime") {
        data.append("item_start_time", moment(itemstart).utc(false));
        data.append("item_close_time", moment(itemclose).utc(false));
      } else if (settime === "alltime") {
        data.append("item_start_time", null);
        data.append("item_close_time", null);
      }
      if (is_lsm == "0") {
        data.append("is_lsm", 0);
      } else if (is_lsm == "1") {
        data.append("is_lsm", 1);
        data.append("stores_json", JSON.stringify(storesJson));
      }
      if (
        sizejson[sizejson.length - 1].order_modes_price.length > 0 &&
        sizejson[sizejson.length - 1].order_modes_price[
          sizejson[sizejson.length - 1].order_modes_price.length - 1
        ].mrp > 0
      ) {
        if (this.isUnique(sizejson)) {
          data.append("itemSizes", SizeJsonString);
          this.props.editItem(id, data);
        } else {
          toast.error("Please fill unique POS & ERP codes", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      } else {
        alert("Please fill all mandatory variant fields");
      }
    }
  };
  handleAllergenInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      this.setState({ allergens: e });
    } else {
      this.setState({ allergens: [] });
    }
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let msg;
    let messcolor;
    const { isUpdated, message, groups, itemData } = this.props;
    const {
      sizejson,
      settime,
      hero_item,
      priority,
      itemstart,
      mode,
      itemclose,
      is_lsm,
      storesJson,
      itemname,
      allergens,
      banner_title,
      banner_description,
    } = this.state;
    if (isUpdated) {
      msg = message;
      messcolor = "green";
    } else {
      msg = message;
      messcolor = "red";
    }
    return (
      <>
        <Modal
          className="fade bd-example-modal-lg"
          show={this.props.editItemModal}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>Edit Product</Modal.Title>
            <Button
              variant=""
              className="close"
              onClick={() => this.props.setEditItemModal(false)}
            >
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xl={12}>
                <Card>
                  <Card.Header style={{ margin: "auto" }}>
                    <Card.Title>
                      <div className="container">
                        <div className="row">
                          {/* <input id="checkboxCustom2" type="checkbox" checked={this.state.is_featured} onChange={this.handleFeatured} className="checkbox-template" /> */}
                          <Toggle
                            className="custom-classname"
                            checked={this.state.is_featured}
                            icons={false}
                            onChange={this.handleFeatured}
                          />
                          <label
                            className="ml-2 mb-1"
                            htmlFor="checkboxCustom2"
                          >
                            Feature Product
                          </label>
                        </div>
                      </div>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Tab.Container
                      defaultActiveKey={this.state.selectedTab}
                      activeKey={this.state.selectedTab}
                    >
                      <Nav as="ul" className="nav-pills mb-4 light">
                        <Nav.Item
                          as="li"
                          key={1}
                          onClick={() => this.setState({ selectedTab: "one" })}
                        >
                          <Nav.Link eventKey={"one"}>Info</Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          key={2}
                          onClick={() => this.setState({ selectedTab: "two" })}
                        >
                          <Nav.Link eventKey={"two"}>Price</Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          key={3}
                          onClick={() =>
                            this.setState({ selectedTab: "three" })
                          }
                        >
                          <Nav.Link eventKey={"three"}>Allergens</Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          key={4}
                          onClick={() => this.setState({ selectedTab: "four" })}
                        >
                          <Nav.Link eventKey={"four"}>Image</Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          key={5}
                          onClick={() => this.setState({ selectedTab: "five" })}
                        >
                          <Nav.Link eventKey={"five"}>Banner</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content className="pt-4">
                        <Tab.Pane eventKey={"one"} key={1}>
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Product Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  id="itemname"
                                  type="text"
                                  name="itemname"
                                  value={itemname}
                                  required
                                  data-msg="Please enter Item Name"
                                  className="form-control"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-4 col-12">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Priority
                                </label>
                                <input
                                  id="priority"
                                  type="text"
                                  name="priority"
                                  value={priority}
                                  required
                                  data-msg="Please enter Priority"
                                  className="form-control"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 col-sm-6 col-12">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Product Category{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  name="group"
                                  className="form-control mt-2"
                                  required
                                  data-msg="Please select Group"
                                  onChange={this.handleInputChange}
                                >
                                  <option disabled>Select Category</option>
                                  {groups &&
                                    groups.map((group, index) => (
                                      <option
                                        key={index}
                                        value={group.group_id}
                                        {...(itemData.item_group_id &&
                                          itemData.item_group_id.group_id ===
                                            group.group_id && {
                                            selected: true,
                                          })}
                                      >
                                        {group.group_name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          {sizejson &&
                            sizejson.length > 0 &&
                            sizejson.map((x: any, ind: any) => {
                              return (
                                <div key={ind}>
                                  <div className="row">
                                    <div className="col-lg-12 col-sm-6 col-6">
                                      <div className="form-group">
                                        <label className="form-control-label mb-3">
                                          Description
                                        </label>
                                        <input
                                          id="description"
                                          type="text"
                                          name="description"
                                          value={x.description}
                                          className="form-control"
                                          onChange={(e) =>
                                            this.handleVariantInputChange(
                                              e,
                                              ind
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          <div className="row">
                            <div className="col-lg-12 col-sm-6 col-12">
                              <div className="form-group">
                                <label className="form-control-label mb-3">
                                  Tags{" "}
                                </label>
                              </div>
                              <div>
                                <div className="row">
                                  <div className="col-5">
                                    <input
                                    id="checkboxCustom1"
                                    type="checkbox"
                                    name="taxstatus"
                                    checked={this.state.is_alcohol}
                                    onChange={this.handleAlcohol}
                                    className="checkbox-template"
                                  />
                                  <label
                                    className="ml-2"
                                    htmlFor="checkboxCustom1"
                                  >
                                    Product contains alcohol
                                  </label>
                                  </div>
                                  <div className="col-2">
                                    <input
                                      id="checkboxCustom1"
                                      type="checkbox"
                                      name="is_halal"
                                      checked={this.state.is_halal}
                                      onChange={this.handleHalal}
                                      className="checkbox-template"
                                    />
                                    <label
                                      className="ml-2"
                                      htmlFor="checkboxCustom1"
                                    >
                                      Halal
                                    </label>
                                  </div>
                                  <div className="col-2">
                                    <input
                                      id="checkboxCustom1"
                                      type="checkbox"
                                      name="is_veg"
                                      checked={this.state.is_veg}
                                      onChange={this.handleVeg}
                                      className="checkbox-template"
                                    />
                                    <label
                                      className="ml-2"
                                      htmlFor="checkboxCustom1"
                                    >
                                      Veg
                                    </label>
                                  </div>
                                  <div className="col-3">
                                    <input
                                      id="checkboxCustom1"
                                      type="checkbox"
                                      name="taxstatus"
                                      checked={this.state.is_vegan}
                                      onChange={this.handleVegan}
                                      className="checkbox-template"
                                    />
                                    <label
                                      className="ml-2"
                                      htmlFor="checkboxCustom1"
                                    >
                                      Vegan
                                    </label>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={"two"} key={2}>
                          {/* <div className="row">
                                    <div className="col-12" >
                                        <div className="form-group">
                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                            <Select
                                                isMulti
                                                name="mode"
                                                value={mode}
                                                options={this.props.orderingModes}
                                                className="text-capitalize basic-multi-select mt-2"
                                                classNamePrefix="select"
                                                onChange={(e, i) => this.handleOrderModesInputChange(e, i)}
                                            />
                                        </div>
                                    </div>
                                </div> */}
                          {sizejson &&
                            sizejson.length > 0 &&
                            sizejson.map((x: any, ind: any) => {
                              return (
                                <div key={ind}>
                                  <div className="row">
                                    {x.order_modes_price &&
                                      x.order_modes_price.length > 0 &&
                                      x.order_modes_price.map(
                                        (channel: any, index: any) => (
                                          <>
                                            {/* <div className="col-12 mb-2">
                                                                <strong>{index + 1} -</strong>
                                                                <strong style={{ fontSize: '16px' }} className="ml-2">
                                                                    {channel.label}
                                                                </strong>
                                                            </div> */}
                                            <div className="col-12">
                                              <div className="form-group">
                                                <label className="form-control-label mb-3">
                                                  Max Retail Price{" "}
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </label>
                                                <input
                                                  name="mrp"
                                                  type="number"
                                                  value={channel.mrp}
                                                  data-msg="Please enter mrp"
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    this.handleVariantPriceInputChange(
                                                      e,
                                                      index,
                                                      ind
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            {/* <div className="col-6">
                                                                <div className="form-group">
                                                                    <label className="form-control-label mb-3">Extra Price</label>
                                                                    <input
                                                                        name="extra_price"
                                                                        type="number"
                                                                        value={channel.extra_price}
                                                                        className="form-control"
                                                                        onChange={e => this.handleVariantPriceInputChange(e, index, ind)}
                                                                    />
                                                                </div>
                                                            </div> */}
                                          </>
                                        )
                                      )}
                                  </div>
                                </div>
                              );
                            })}
                        </Tab.Pane>
                        <Tab.Pane eventKey={"three"} key={3}>
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="form-control-label">
                                  Select allergens for product{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  isMulti
                                  value={allergens}
                                  // defaultValue={allergens}
                                  name="allergens"
                                  options={this.props.allergens}
                                  className="text-capitalize basic-multi-select mt-2"
                                  classNamePrefix="select"
                                  onChange={(e, i) =>
                                    this.handleAllergenInputChange(e, i)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={"four"} key={4}>
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                {sizejson.map((x: any, ind: any) => {
                                  return (
                                    <div key={ind}>
                                      <div className="row">
                                        <div className="col-lg-12 col-sm-6 col-6">
                                          <div className="form-group">
                                            <label className="form-control-label mb-3">
                                              Item Image{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              id="files"
                                              type="file"
                                              name="files"
                                              className="form-control-file"
                                              onChange={(e) =>
                                                this.fileSelectedHandler(e, ind)
                                              }
                                            />
                                            <p
                                              style={{
                                                color: "red",
                                                fontSize: "12px",
                                              }}
                                            >
                                              Image size should be less than 1
                                              mb
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={"five"} key={5}>
                          <div className="row">
                            <div className="col-lg-6 col-sm-6 col-6">
                              <div>
                                <input
                                  id="checkboxCustom1"
                                  type="checkbox"
                                  name="taxstatus"
                                  checked={hero_item}
                                  onChange={this.handleHeroItem}
                                  className="checkbox-template"
                                />
                                <label
                                  className="ml-2"
                                  htmlFor="checkboxCustom1"
                                >
                                  Item Banner
                                </label>
                              </div>
                            </div>
                          </div>
                          {hero_item && (
                            <div className="row">
                              <div className="col-12 mt-2">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Banner Title{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    id="itemname"
                                    maxLength={30}
                                    type="text"
                                    value={banner_title}
                                    name="banner_title"
                                    required
                                    data-msg="Please enter banner title"
                                    className="form-control"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Banner Description{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    id="itemname"
                                    maxLength={60}
                                    type="text"
                                    value={banner_description}
                                    name="banner_description"
                                    required
                                    data-msg="Please enter banner description"
                                    className="form-control"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                              {/* <div className="col-6 mt-2">
                                            <div className="form-group">
                                                <label className="form-control-label mb-3">Web Banner <span className="text-danger">*</span></label>
                                                <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={this.heroItemImageSelectedHandler} />
                                                <p style={{color:"red",fontSize:"12px"}}>Image size should be less than 1 mb</p>
                                            </div>
                                        </div> */}
                              {/* <div className="col-6 mt-2">
                                            <div className="form-group">
                                                <label className="form-control-label mb-3">App Banner</label>
                                                <input id="files" type="file" name="files" accept="image/*" className="form-control-file" onChange={this.heroItemMobileImageSelectedHandler} />
                                                <p style={{color:"red",fontSize:"12px"}}>Image size should be less than 1 mb</p>
                                            </div>
                                        </div> */}
                            </div>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button
              variant="danger light"
              onClick={() => this.props.setEditItemModal(false)}
            >
              Close
            </Button> */}
            {this.renderButtons()}

            {/* <Button variant="" type="button" className="btn btn-primary">
                    Edit Item
                    </Button> */}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    groups: state.menu.groups,
    states: state.setting.states,
    menus: state.menu.menus,
    stores: state.menu.storesoptions,
    // itemData: state.menu.itemData,
    // sizejson: state.menu.sizejson,
    taxData: state.setting.taxData,
    message: state.menu.message,
    isUpdated: state.menu.isUpdated,
    duplicateItemPos: state.menu.duplicateItemPos,
    duplicateItemErp: state.menu.duplicateItemErp,
    orderingModes: state.menu.orderModes,
    allergens: state.menu.allergens,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    editItem: function (id: any, formData: any) {
      dispatch(editItem(id, formData));
    },
    checkDuplicatePosCode: (pos_code: any) => {
      dispatch(checkDuplicatePosCode(pos_code));
    },
    checkDuplicateErpId: (erp_id: any) => {
      dispatch(checkDuplicateErpId(erp_id));
    },
    groupsList: function () {
      dispatch(groupsList());
    },
    // getMenuItem: function (id: number) {
    //     dispatch(getMenuItem(id));
    // },
    menusList: function () {
      dispatch(menusList());
    },
    storesList: () => {
      dispatch(storesListForMultiSelect());
    },
    statesList: function () {
      dispatch(statesList());
    },
    OrderingModes: () => {
      dispatch(OrderingModes());
    },
    allergensList: function () {
      dispatch(allergensList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditMenuItem);
