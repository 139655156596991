import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import Select from 'react-select';
import { EditModGroupProps, EditModGroupState } from '../../../interface/newMenu';
import { secretKey } from '../../../../secret';
import { editModGroup, getModGroup, groupsListForMultiSelect, itemsListForMultiSelect, logoutUser } from '../../../../redux';
import { Nav, Tab } from 'react-bootstrap';
import { editChoice } from '../../../../redux/actions/menuAction';

class EditMod extends Component<EditModGroupProps, EditModGroupState> {
    constructor(props: any) {
        super(props);
        this.state = {
            modgroupname: "",
            groupJson: [],
            itemsJson: [],
            poscode: "",
            erpid: "",
            modgroupdesc: "",
            type: "",
            level: "",
            priority: "",
            choicesjson:[
                {
                    modifier_name: "",
                    priority: "",
                    modifier_type: "",
                    modifier_cost_price: 0,
                    modifier_sale_price: "",
                    is_publish:1
                }
            ],
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleModifiersLevel = this.handleModifiersLevel.bind(this);
    }
    componentDidMount() {
        let id = this.props.id;
        console.log("choice id child",id)
        this.props.getModGroup(id);
        this.props.groupsList();
        this.props.itemsListForMultiSelect();
        document.title = "Choices"
    }
    
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState({
            modgroupname: nextProps.modGroupData.mod_group_name,
            poscode: nextProps.modGroupData.pos_code,
            erpid: nextProps.modGroupData.erp_id,
            modgroupdesc: nextProps.modGroupData.mod_group_description,
            groupJson: nextProps.modGroupData.group_json && JSON.parse(nextProps.modGroupData.group_json),
            itemsJson: nextProps.modGroupData.items_json && JSON.parse(nextProps.modGroupData.items_json),
            type: nextProps.modGroupData.modifiers_type,
            level: nextProps.modGroupData.type,
            priority: nextProps.modGroupData.priority,
            choicesjson: nextProps.modGroupData.modifiers
        })
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleModifiersLevel(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    isModGroupReady = () => {
        const { modgroupname, type, groupJson, level, itemsJson } = this.state
        return (modgroupname !== "" && type !== "" && (level == "group" ? groupJson && groupJson.length > 0 : itemsJson && itemsJson.length > 0));
    }
    handleGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ itemsJson: [], groupJson: e });
        }
    };
    handleItemsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ groupJson: [], itemsJson: e });
        }
    }
    handleSubmit = (event: any) => {
        let { modgroupname, modgroupdesc, type, poscode, erpid, groupJson, level, itemsJson, priority,choicesjson } = this.state;
        let id = this.props.id;
        let data: any = {
            mod_group_name: modgroupname,
            mod_group_description: modgroupdesc,
            priority: priority,
            modifiers_type: type,
            is_publish:1,
            type: level,
            choices : choicesjson
        }
        if (level == 'group') {
            data["group_json"] = JSON.stringify(groupJson);
            data["items_json"] = null;
        } else if (level == 'item') {
            data["group_json"] = null;
            data["items_json"] = JSON.stringify(itemsJson);
        }
        if (poscode !== null && poscode !== "") {
            data['pos_code'] = poscode;
        } else {
            data['pos_code'] = null;
        }
        if (erpid !== null && erpid !== "") {
            data['erp_id'] = erpid
        } else {
            data['erp_id'] = null;
        }
        this.props.editChoice(id, data);
        event.preventDefault()
    }
    handleChoiceInputChange = async (event: { target: { name: any; value: any; }; },index:any)  => {
        const list: any = this.state.choicesjson;
        list[index][event.target.name] = event.target.value;
        this.setState({choicesjson : list});
    }
    handleRemoveChoiceClick = (index: any) => {
        const list = this.state.choicesjson;
        list.splice(index, 1);
        this.setState({ choicesjson: list });

    };
    handleAddChoiceClick = (i: any) => {
        const list: any = this.state.choicesjson;
        if (list[i]["modifier_name"] == "" || list[i]["modifier_sale_price"] == "") 
        {
            alert("Please fill in current item")
        }  
        else {
            const newlist: any = {
                modifier_name: "",
                priority: "",
                modifier_type: "",
                modifier_cost_price: 0,
                modifier_sale_price: "",
                is_publish:1
            }
            const newMilestoneList: any = this.state.choicesjson.concat(newlist)
            this.setState({ choicesjson: newMilestoneList });
        }
            
    };
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        let { groupJson, itemsJson,choicesjson } = this.state;
        const { isUpdated, message, modGroupData, groups, items } = this.props;
        if (isUpdated) {
            msg = message;
            messcolor = 'green'
        } else {
            msg = message;
            messcolor = 'red'
        }
        return (
            <>
            {this.props.id && 
            <Tab.Container defaultActiveKey={"one"}>
                <Nav as='ul' className='nav-pills mb-4 light'> 
                    <Nav.Item as='li' key={1}>
                    <Nav.Link eventKey={"one"}>
                        Info
                    </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as='li' key={2}>
                    <Nav.Link eventKey={"two"}>
                        Choice Level
                    </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as='li' key={3}>
                    <Nav.Link eventKey={"three"}>
                        Choices
                    </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content className='pt-4'>                        
                    <Tab.Pane eventKey={"one"} key={1}>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label className="form-control-label">Choice Group <span className="text-danger">*</span></label>
                                    <input id="modgroupname" type="text" name="modgroupname" defaultValue={modGroupData.mod_group_name} required data-msg="Please enter Modifier Name" className="form-control" onChange={this.handleInputChange} />
                                </div>
                            </div>
                            <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-control-label">Choice Type <span className="text-danger">*</span></label>
                                <select name="type" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleInputChange}>
                                    {/* <option {...modGroupData.modifiers_type === 'single' && { selected: true }} value='single'>Single Select</option> */}
                                    <option {...modGroupData.modifiers_type === 'multiple' && { selected: true }} value='multiple' >Multi Select</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-control-label">Priority</label>
                                <input id="priority" type="number" name="priority" defaultValue={modGroupData.priority} required data-msg="Please enter Priority" className="form-control" onChange={this.handleInputChange} />
                            </div>
                        </div>
                        </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey={"two"} key={2}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-control-label">Choices Level <span className="text-danger">*</span></label>
                                    <select name="level" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleModifiersLevel}>
                                        <option value='group' {...modGroupData.type == 'group' && { selected: true }}>Category</option>
                                        <option value='item' {...modGroupData.type == 'item' && { selected: true }}>Product</option>
                                    </select>
                                </div>
                            </div>

                            {this.state.level === "group" &&
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-control-label">Select Categories <span className="text-danger">*</span></label>
                                        <Select
                                            isMulti
                                            name="groups"
                                            value={groupJson}
                                            options={groups}
                                            className="text-capitalize basic-multi-select mt-2"
                                            classNamePrefix="select"
                                            onChange={(e, i) => this.handleGroupsInputChange(e, i)}
                                        />
                                    </div>
                                </div>
                            }
                            {this.state.level === "item" &&
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="form-control-label">Select Products <span className="text-danger">*</span></label>
                                        <Select
                                            isMulti
                                            name="items"
                                            options={items}
                                            value={itemsJson}
                                            className="text-capitalize basic-multi-select mt-2"
                                            classNamePrefix="select"
                                            onChange={(e, i) => this.handleItemsInputChange(e, i)}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey={"three"} key={3}>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                {choicesjson && choicesjson.map((x: any, i: any) => {
                                    return (
                                        <div>
                                        <div key={i} className="row">
                                            <div className="col-6">
                                                <div className="form-group">
                                                    {/* <label className="form-control-label">Choice Name <span className="text-danger">*</span></label> */}
                                                    <input id="modifier_name" placeholder='New Choice Name (e.g. Extra Cheese)' type="text" name="modifier_name" value={x.modifier_name} required data-msg="Please enter choice name" className="form-control" onChange={e => this.handleChoiceInputChange(e,i)} />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    {/* <label className="form-control-label">Price <span className="text-danger">*</span></label> */}
                                                    <input id="modifier_sale_price" placeholder='New Choice Price' type="text" name="modifier_sale_price" value={x.modifier_sale_price} required data-msg="Please enter choice sale price" className="form-control" onChange={e => this.handleChoiceInputChange(e,i)} />
                                                </div>
                                            </div>
                                            
                                                <div className="col-lg-2 col-md-2 col-2 text-right mt-2">
                                                {choicesjson.length !== 1 &&
                                                    <button className="btn btn-sm btn-outline-danger"
                                                        onClick={() => this.handleRemoveChoiceClick(i)}
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                            {/* {""} */}
                                                            </button>}
                                                {choicesjson.length - 1 === i && <button className="btn btn-sm btn-primary ml-2" 
                                                onClick={() => this.handleAddChoiceClick(i)}
                                                >
                                                <i className="fa fa-plus"></i>
                                                {/* Add Milestone */}
                                                </button>}
                                            </div>
                                            
                                            
                                        </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    </Tab.Pane>
                    <div className="form-group d-flex justify-content-end mt-4">
                        <button onClick={this.handleSubmit} disabled={!this.isModGroupReady()} className="btn btn-primary">Update Choice</button>
                    </div>
                </Tab.Content>
            </Tab.Container>
                            }
            </>
            
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        // modGroupData: state.menu.modGroupData,
        groups: state.menu.groupsptions,
        items: state.menu.allActiveItems,
        message: state.menu.message,
        isUpdated: state.menu.isUpdated
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        editChoice: function (id: any, data: any) {
            dispatch(editChoice(id, data));
        },
        getModGroup: function (id: number) {
            dispatch(getModGroup(id));
        },
        groupsList: function () {
            dispatch(groupsListForMultiSelect())
        },
        itemsListForMultiSelect: function () {
            dispatch(itemsListForMultiSelect())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditMod);