import React, { Component, Fragment } from "react";
import { Redirect, Link } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import jwt from "jsonwebtoken";
import { connect } from "react-redux";
import { secretKey } from "../../../secret";
import {
  getMenuItem,
  groupsList,
  groupsListByMenuId,
  itemsList,
  logoutUser,
  menusList,
  publishLater,
  publishMenu,
} from "../../../redux";
import PageTitle from "../../layouts/PageTitle";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { NewMenuProps } from "../../interface/newMenu";
import { Button, Modal, Container } from "react-bootstrap";
import Select from "react-select";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import AddMenuItem from "./menuItem/add-menu-item";
import EditMenuItem from "./menuItem/edit-menu-item";
import AddGroup from "./groups/add-group";
import Api from "../../../components/Api";
import { API_URL } from "../../../client-config";
import EditGroup from "./groups/edit-group";
class NewMenu extends Component<
  NewMenuProps,
  { 
    categoryModal: any;
    editCategoryModal:any;
    itemModal: any;
    editItemModal:any; 
    id:any;
    itemData:any; 
    sizejson:any;
    add_group_id:any;
    add_group_name:any,
    categories:any 
    items:any
    selectedKey:any
    group_id:any
    groupData:any
  }
> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      categoryModal: "",
      itemModal: "",
      editItemModal : "",
      id : "",
      itemData:{},
      sizejson:{},
      add_group_id:"",
      add_group_name:"",
      categories:[],
      items:[],
      selectedKey:"",
      editCategoryModal:"",
      group_id:"",
      groupData:{}
    };
  }
  componentDidMount() {
    this.props.menusList();
    this.props.groupsList();
    // this.props.groupsListByMenuId(1);
    this.props.itemsList();
    document.title = "Menus";
  }
  componentDidUpdate(prevProps:any) 
  {  
    if (this.props.groups.length > prevProps.groups.length)
    {
        this.setState({ categories: this.props.groups });
    }
    if(this.props.items.length > prevProps.items.length){
      this.setState({items:this.props.items})
    }
  }
  shouldComponentUpdate(nextProps:any) {
    return true;
  }
  setCategoryModal = (condition: any) => {
    this.setState({ categoryModal: condition });
  };
  setEditCategoryModal = (condition: any,id?:any) => {
    this.setState({ editCategoryModal: condition });
    if(condition == true){
      let token: any = sessionStorage.getItem('token');
      Api.get(`/admin/group/${id}`, {
          headers: { 'Authorization': 'Bearer ' + token }
      })
          .then((response) => {
              if (response.data.success) {
                this.setState({groupData:response.data.successResponse})
              }
          }).catch(err => {
              if (err.response) {
                  console.log(err.response.data.message)
              } else {
                  alert(err.message)
              }
          });
    }
    
  };
  setItemModal = (condition: any,group_id?:any,group_name?:any) => {
    this.setState({ itemModal: condition });
    if(group_id && group_name)
    {
      this.setState({add_group_id:group_id})
      this.setState({add_group_name:group_name})
    }
  };

  setEditItemModal = (condition: any,id?: any) => {
    this.setState({ editItemModal: condition });
    if(condition == true)
    {
      let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/menu_item/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
          .then((response) => {
              if (response.data.success) {
                  console.log("response API",response.data.successResponse)
                  this.setState({itemData:response.data.successResponse})
                  this.setState({sizejson:JSON.parse(response.data.successResponse.item_size)})
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
      // this.props.getMenuItem(id);    
    }
  };
  setEditGroupModal = (condition: any,id?: any) => {
    this.setState({ editItemModal: condition });
    if(condition == true)
    {
        
    }
  };
  setEditItemId = (id:any) => {
    this.setState({id:id})
  }
  getItemId = () => {
    return this.state.id
  }
  handleLinkSelect = (selectedKey:any,group_id?:any) => {
    this.setState({selectedKey:selectedKey})
    if(group_id)
    {
      this.setState({group_id:group_id})
    }
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    return (
      <Fragment>
        {/* <PageTitle activeMenu='Tab' motherMenu='Bootstrap' /> */}
        {/* Add Category Modal */}
                
            <AddGroup 
              setCategoryModal={async (e) => this.setCategoryModal(e)} categoryModal={this.state.categoryModal} onModalClose={undefined} onSave={undefined} columns={undefined} validateState={undefined} ignoreEditable={undefined}/>
            
            <EditGroup 
              match={undefined} 
              setEditCategoryModal={async (e: any) => this.setEditCategoryModal(e)} 
              editCategoryModal={this.state.editCategoryModal} 
              group_id={this.state.group_id}
              groupData={this.state.groupData}
              />
            
        {/* Add Item Modal */}
        
            <AddMenuItem
              itemModal={this.state.itemModal}
              setItemModal={async (e) => this.setItemModal(e)}
              onModalClose={undefined}
              onSave={undefined}
              columns={undefined}
              validateState={undefined}
              ignoreEditable={undefined}  
              add_group_id={this.state.add_group_id}
              add_group_name={this.state.add_group_name}
            />
          
        {/* Edit menu item modal */}          
            <EditMenuItem 
          editItemModal={this.state.editItemModal}
          getItemId={this.getItemId}
          itemData={this.state.itemData ? this.state.itemData : {}}
          sizejson={this.state.sizejson}
          setEditItemModal={async (e) => this.setEditItemModal(e)}
          match={undefined} 
          id={this.state.id} 
          getMenuItem={function (id: any): {} {
            throw new Error("Function not implemented.");
          } }            />
          
        <Row>
          <Col xl={12}>
            <Card>
              <Card.Header>
                <Card.Title>Categories</Card.Title>
                <Button
                  variant="primary"
                  className="p-2"
                  onClick={() => this.setCategoryModal(true)}
                  // style={{ marginLeft: "55rem" }}
                >
                  Add Category
                </Button>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Tab.Container
                    defaultActiveKey={
                      this.props.groups &&
                      this.props.groups.length > 0 &&
                      this.props.groups[0].group_name.toLowerCase()
                    }
                  >
                    <Col xl={3}>
                      <Nav as="ul" onSelect={(e)=>this.handleLinkSelect(e)} className="flex-column nav-pills mb-3">
                        {this.props.groups &&
                      this.props.groups.length > 0 && this.props.groups.map((data: any, i: any) => (
                          <Nav.Item onSelect={(e:any)=>this.handleLinkSelect(e,data.group_id)} as="li" key={i}>
                            <Nav.Link eventKey={data.group_name.toLowerCase()}>
                              <span className="text">  {data.group_name} </span>
                              <span className="iconA">
                              {this.state.selectedKey == data.group_name.toLowerCase() && 
                                  <i  onClick={()=>this.setEditCategoryModal(true,data.group_id)} style={{"fontSize":"22px","position":"relative",top:"3px"}} 
                                  className="ml-4 fa fa-edit"></i>
                                }
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </Col>
                    <Col xl={9}>
                      <Tab.Content className="ml-2">
                        {this.props.groups &&
                      this.props.groups.length > 0 && this.props.groups.map((data: any, i: any) => (
                          <Tab.Pane
                            eventKey={data.group_name.toLowerCase()}
                            key={i}
                          >
                            <div className="row">
                              <h3 className="mt-2">{data.group_name}</h3>
                              <div
                                className="col"
                                // style={{"textAlign": "-webkit-right"}}
                              >
                                <Button
                                  variant="primary"
                                  className="mb-2 mr-2 p-2"
                                  style={{ float:"right" }}
                                  onClick={() => this.setItemModal(true,data.group_id,data.group_name)}
                                >
                                  Add Product
                                </Button>
                              </div>
                            </div>
                            <div className="row mt-2">
                              {this.props.items.map((item: any, i: any) =>
                                item.item_group_id == data.group_id ? (
                                  <div
                                    className="col-xl-4 col-lg-12 col-xxl-4 col-sm-12"
                                    style={{ width: "100%", height: "200px" }}
                                  >
                                    { item.variants && item.variants.length > 0 && 
                                      <div
                                      className="btn card"
                                      style={{backgroundColor:"#c9f5e2"}}
                                      onClick={()=>this.setEditItemModal(true,item.menu_item_id)}
                                    >
                                      <div className='profile-photo'>
                                      <img
                                        src={`${API_URL}${item.variants[0].image_url}`}
                                        style={{height:"6rem",width:"6rem",position:"relative",top:"20px"}}
                                        className='m-auto img-fluid rounded-circle d-block'
                                        alt=''
                                      />
                                    </div>
                                      <div className=" card-body text-center ai-icon  text-primary" >
                                        <h4 className="my-2">
                                          {item.item_name}
                                        </h4>
                                      </div>
                                    </div>
                                    }
                                    

                                  </div>
                                ) : (
                                  <></>
                                )
                              )}
                            </div>
                          </Tab.Pane>
                        ))}
                      </Tab.Content>{" "}
                    </Col>
                  </Tab.Container>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    data: state.menu.menus,
    groups: state.menu.groups,
    items: state.menu.items,
    itemData: state.menu.itemData,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    menusList: function () {
      dispatch(menusList());
    },
    groupsListByMenuId: function (id: any) {
      dispatch(groupsListByMenuId(id));
    },
    groupsList: function () {
      dispatch(groupsList());
    },
    publishMenu: function (id: any, data: any, menu_name: any) {
      dispatch(publishMenu(id, data, menu_name));
    },
    publishLater: function (id: any, data: any, menu_name: any) {
      dispatch(publishLater(id, data, menu_name));
    },
    itemsList: function () {
      dispatch(itemsList());
    },
    getMenuItem: function (id: number) {
      dispatch(getMenuItem(id));
  },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewMenu);
