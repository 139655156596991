import {
    MENUS_LIST, GET_MENU, EDIT_MENU, ADD_MENU, GROUPS_LIST, GROUP_OPTIONS, ADD_GROUP, GET_GROUP, EDIT_GROUP,
    ITEMS_LIST, ADD_ITEMS, GET_ITEM, EDIT_ITEM, COMBOS_LIST, ADD_COMBO, GET_COMBO, EDIT_COMBO, COMBO_OPTIONS, MOD_GROUP_LIST, ADD_MOD_GROUP,
    MOD_LIST, ADD_MOD, EDIT_MOD, GET_MOD, ING_LIST, ADD_ING, ING_CAT_LIST, ING_UOM_LIST, REC_LIST,
    ADD_REC, GET_MOD_GROUP, CHECK_DUP_POS, STORES_OPTIONS, COMBO_CHOICE_ITEMS, ACTIVE_ITEMS_OPTIONS, CHECK_DUP_ERP,
    GET_BRANCH_MENU, STATUS_CHANGE_REASONS, SEARCH_MENU_ITEM, SEARCH_GROUPS, SEARCH_COMBOS, ORDER_MODES, ALLERGENS, STAMPS, GET_STAMP
} from './menuType'
import jwt_decode from "jwt-decode";
import Api from '../../components/Api';
import swal from "sweetalert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
const IsJsonString = (str: any) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
export const menusList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/menus', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MENUS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addMenu = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        data.user_info = jwt_decode(token);
        Api.post('/admin/add_menu', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/menus";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            error = "Menu already exist"
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ")
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getMenu = (id: number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/menu/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_MENU,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editMenu = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        data.user_info = jwt_decode(token);
        Api.put(`/admin/edit_menu/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/menus";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const groupsListByMenuId = (menu_id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        //if condition to prevent the Api hit without id
        if (menu_id === "") {
            dispatch({
                type: GROUPS_LIST,
                payload: ""
            })
        } else {
            Api.get(`/admin/menu/groups/${menu_id}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: GROUPS_LIST,
                            payload: response.data.successResponse
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        dispatch({
                            type: GROUPS_LIST,
                            payload: ""
                        })
                        // console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}
export const publishMenu = (id: any, data: any, menu_name: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        data.menu_name = menu_name;
        data.user_info = jwt_decode(token);
        Api.put(`/admin/menu_publish/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/menus"
                }
            }).catch(err => {
                if (err.response) {
                    alert(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const publishLater = (id: any, data: any, menu_name: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        data.menu_name = menu_name;
        data.user_info = jwt_decode(token);
        Api.put(`/admin/publish_menu_future/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/menus"
                }
            }).catch(err => {
                if (err.response) {
                    alert(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const groupsList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/groups', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GROUPS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const groupsListForMultiSelect = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/groups_options', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GROUP_OPTIONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const itemsListForMultiSelect = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/getAllActiveItems', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ACTIVE_ITEMS_OPTIONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const storesListForMultiSelect = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/storesListForMenu', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: STORES_OPTIONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const addGroup = (formData: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        console.log("Form Data",formData)
        formData.append('user_info',JSON.stringify(jwt_decode(token)))
        Api.post('/admin/add_group', formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                   
                    swal(
                        "Good job!",
                        `Category added successfully`,
                        "success"
                     )
                    // window.location.href = "/groups";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'EPR Id already exist'
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addCategory = (formData: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        // formData.append('user_info',JSON.stringify(jwt_decode(token)))
        formData.user_info = JSON.stringify(jwt_decode(token))
        console.log("formData",formData)
        Api.post('/admin/add_category', formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                // "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    swal(
                        "Good job!",
                        `Category added successfully`,
                        "success"
                     )
                    dispatch(groupsList())
                    // window.location.href = "/groups";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'EPR Id already exist'
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getGroup = (id: number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/group/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_GROUP,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editGroup = (id: any, formData: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/admin/edit_category/${id}`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                // "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch(groupsList())
                    swal(
                        "Good job!",
                        `Category updated successfully`,
                        "success"
                     )
                    // window.location.href = "/groups";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'EPR Id already exist'
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    // toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editGroupOld = (id: any, formData: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        formData.append('user_info',JSON.stringify(jwt_decode(token)))
        Api.put(`/admin/edit_group/${id}`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/groups";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'EPR Id already exist'
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockgroup = (id: any, isActive: any, group_name: any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = sessionStorage.getItem('token');
        let data = {
            is_active: is_active,
            group_name:group_name,
            user_info: jwt_decode(token)
        }
        Api.put(`/admin/group_block_unblock/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/groups"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const itemsListByGroupId = (group_id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        if (group_id === "") {
            dispatch({
                type: ITEMS_LIST,
                payload: ""
            })
        } else {
            Api.get(`/admin/group/items/${group_id}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: ITEMS_LIST,
                            payload: response.data.successResponse
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        dispatch({
                            type: ITEMS_LIST,
                            payload: ""
                        })
                        // console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}
export const comboChoiceItemsByGroupId = (group_id: any, index: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        if (group_id === "") {
            dispatch({
                type: COMBO_CHOICE_ITEMS,
                choiceIndex: "",
                payload: []
            })
        } else {
            Api.get(`/admin/combo_choice_items/${group_id}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: COMBO_CHOICE_ITEMS,
                            choiceIndex: index,
                            payload: response.data.successResponse
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        dispatch({
                            type: COMBO_CHOICE_ITEMS,
                            choiceIndex: index,
                            payload: []
                        })
                        // console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}
export const itemsList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/menu_items', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ITEMS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addItems = (formData: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        formData.append('user_info',JSON.stringify(jwt_decode(token)))
        Api.post('/admin/add_menu_item', formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch(itemsList())
                    swal(
                        "Good job!",
                        `Product added successfully`,
                        "success"
                     )
                    // toast.success("Product added successfully", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    // window.location.href = "/items"
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'Already exist';
                            } else {
                                error = 'Already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    swal("Oops", error , "error")
                    // toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const checkDuplicatePosCode = (pos_code: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/check_item_poscode/${pos_code}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                dispatch({
                    type: CHECK_DUP_POS,
                    duplicateItemPos: false,
                })
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: CHECK_DUP_POS,
                        duplicateItemPos: true,
                    })
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const checkDuplicateErpId = (erp_id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/check_item_erpId/${erp_id}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                dispatch({
                    type: CHECK_DUP_ERP,
                    duplicateItemErp: false,
                })
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: CHECK_DUP_ERP,
                        duplicateItemErp: true,
                    })
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getMenuItem = (id: number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/menu_item/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_ITEM,
                        sizejson: response.data.successResponse.item_size !== "" ? JSON.parse(response.data.successResponse.item_size) : "",
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockitem = (id: any, isActive: any, itemName: any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = sessionStorage.getItem('token');
        let data = {
            is_active: is_active,
            item_ame:itemName,
            user_info: jwt_decode(token)
        }
        Api.put(`/admin/item_block_unblock/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/menu-items"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editItem = (id: any, formData: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        formData.append('user_info',JSON.stringify(jwt_decode(token)))
        Api.put(`/admin/edit_menu_item/${id}`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch(itemsList())
                    swal(
                        "Good job!",
                        "Product updated successfully",
                        "success"
                     )
                    // toast.success("Product updated successfully, Close", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    // window.location.href = "/items";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'Already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    swal("Oops", error, "error")
                    // toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const combosList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/combos', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: COMBOS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const groupedItemsList = (menu_id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        if (menu_id === "") {
            dispatch({
                type: COMBO_OPTIONS,
                payload: ""
            })
        } else {
            Api.get(`/admin/combo_options/${menu_id}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: COMBO_OPTIONS,
                            payload: response.data.successResponse
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}
export const addCombo = (formData: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        formData.append('user_info',JSON.stringify(jwt_decode(token)))
        Api.post('/admin/add_combo', formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/combos"
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'Already exist';
                            } else {
                                error = 'Already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getCombo = (id: number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/combo/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_COMBO,
                        combojson: IsJsonString(response.data.successResponse.combo_ingredients) ? JSON.parse(response.data.successResponse.combo_ingredients) : [
                            {
                                id: "",
                                itemName: "",
                                poscode: "",
                                choice_group_name: "",
                                groupname: "",
                                groupposcode: "",
                                size: "",
                                quantity: "",
                                limit: "",
                                itemsize: []
                            }
                        ],
                        comboChoices: IsJsonString(response.data.successResponse.comboChoices) ? JSON.parse(response.data.successResponse.comboChoices) : [
                            {
                                group_id: "",
                                group_erp_id: "",
                                group_name: "",
                                menu_item_id: "",
                                choicesItemOptions: [],
                                size: "",
                                itemsize: []
                            }
                        ],
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockcombo = (id: any, isActive: any, comboName: any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = sessionStorage.getItem('token');
        let data = {
            is_active: is_active,
            combo_name:comboName,
            user_info: jwt_decode(token)
        }
        Api.put(`/admin/combo_block_unblock/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/combos"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editCombo = (id: any, formData: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        formData.append('user_info',JSON.stringify(jwt_decode(token)))
        Api.put(`/admin/edit_combo/${id}`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/combos";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'Already exist';
                            } else {
                                error = 'Already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const modGroupList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/mod_groups', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MOD_GROUP_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addModGroup = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        data.user_info = jwt_decode(token);
        Api.post('/admin/add_mod_group', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    
                    // window.location.href = "/mod-groups";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addChoice = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        data.user_info = jwt_decode(token);
        Api.post('/admin/add_choice', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch(modGroupList())
                    swal(
                        "Good job!",
                        "Choice added successfully",
                        "success"
                     )
                    // window.location.href = "/mod-groups";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    swal("Oops", error, "error")
                    // toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editChoice = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        data.user_info = jwt_decode(token);
        Api.put(`/admin/edit_choice/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) 
                {
                    dispatch(modGroupList())
                    swal(
                        "Good job!",
                        "Choice updated successfully",
                        "success"
                     )
                    // window.location.href = "/mod-groups";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    swal("Oops", error, "error")
                    // toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getModGroup = (id: number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/mod_group/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_MOD_GROUP,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editModGroup = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        data.user_info = jwt_decode(token);
        Api.put(`/admin/edit_mod_group/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    // window.location.href = "/mod-groups";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockModifierGroup = (id: any, isActive: any, history: any, modGroupName: any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = sessionStorage.getItem('token');
        let data = {
            is_active: is_active,
            mod_group_name:modGroupName,
            user_info: jwt_decode(token)
        }
        Api.put(`/admin/mod_group_block_unblock/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    history.push("/mod-groups")
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const modifierListByModGroupId = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/modifiers/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MOD_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: MOD_LIST,
                        payload: ""
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}
export const modifierList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/modifiers', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MOD_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addModifier = (formData: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        formData.append('user_info',JSON.stringify(jwt_decode(token)))
        Api.post('/admin/add_modifier', formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/modifiers";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'Already exist';
                            } else {
                                error = 'Already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getModifier = (id: number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/modifier/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_MOD,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editModifier = (id: any, formData: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        formData.append('user_info',JSON.stringify(jwt_decode(token)))
        Api.put(`/admin/edit_modifier/${id}`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/modifiers";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'Already exist';
                            } else {
                                error = 'Already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const modGroupsList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/activeModGroups`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MOD_GROUP_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const deleteModifier = (id: number, modName: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        let data = {
            modifier_name: modName,
            user_info: jwt_decode(token)
        }
        Api.post(`/admin/del_modifier/${id}`,data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/modifiers";
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getAllMenuList = (store_id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/store/getAllMenu/${store_id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log("response.data.successResponse", response.data.successResponse)
                    let groupsData: any = [];
                    let itemsData: any = [];
                    let comboData: any = [];
                    response.data.successResponse.forEach((obj: any) => {
                        if (obj.group_id) {
                            groupsData.push(obj)
                        } else if (obj.menu_item_id) {
                            itemsData.push(obj)
                        } else if (obj.combo_id) {
                            comboData.push(obj)
                        }
                    })
                    dispatch(searchMenuItem("", itemsData));
                    dispatch(searchGroup("", groupsData));
                    dispatch(searchCombos("", comboData));
                    dispatch({
                        type: GET_BRANCH_MENU,
                        groupsData: groupsData,
                        itemsData: itemsData,
                        combosData: comboData
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const updateItemStatus = (data: any, history: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var store: any = jwt_decode(token);
        data.user_id = store.user_id;
        Api.put(`/store/updateItemStatus/${data.store_id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info("Status changed successfully", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch(getAllMenuList(data.store_id))
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const statusChangeReasons = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/reasons/reasonByType', {
            type: 'menu'
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: STATUS_CHANGE_REASONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const ingList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/ingredients', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ING_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addIng = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/admin/add_ingredient', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.reload();
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const ingCatList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/ing_category', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ING_CAT_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const ingUomList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/ing_uom', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ING_UOM_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
//recipes

export const recipeList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/recipes', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: REC_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addRecipe = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/admin/add_recipe', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.reload();
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const searchGroup = (value: any, groupsData: any) => {
    return function (dispatch: any) {
        const groups = groupsData.filter((item: any) => item.group_id.group_name.toLowerCase().includes(value.toLowerCase()));
        dispatch({
            type: SEARCH_GROUPS,
            searchGroupData: value == "" ? groupsData : groups,
        })
    }
}

export const searchMenuItem = (value: any, menuItemData: any) => {
    return function (dispatch: any) {
        const items = menuItemData.filter((item: any) => item.menu_item_id.item_name.toLowerCase().includes(value.toLowerCase()));
        dispatch({
            type: SEARCH_MENU_ITEM,
            searchItemData: value == "" ? menuItemData : items,
        })
    }
}
export const searchCombos = (value: any, combosData: any) => {
    return function (dispatch: any) {
        const combos = combosData.filter((item: any) => item.combo_id.combo_name.toLowerCase().includes(value.toLowerCase()));
        dispatch({
            type: SEARCH_COMBOS,
            searchComboData: value == "" ? combosData : combos,
        })
    }
}

// api to get all the ordering modes from database
export const OrderingModes = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/order_modes', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                let order_modes_arr: any = [];
                let successResponse = response.data.successResponse;
                for(let i=0; i<successResponse.length; i++){
                    let obj = {
                        label: successResponse[i].name,
                        value: successResponse[i].id
                    }
                    order_modes_arr.push(obj)
                }
                if (response.data.success) {
                    dispatch({
                        type: ORDER_MODES,
                        payload: order_modes_arr
                    })
                }
            }).catch(err => {
                dispatch({
                    type: ORDER_MODES,
                    payload: []
                })
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const allergensList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/allergens', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ALLERGENS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const stampList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/stamps', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: STAMPS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addStamp = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        data.user_info = jwt_decode(token);
        Api.post('/admin/add_stamp', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                dispatch(stampList())
                swal(
                    "Good job!",
                    "Stamp added successfully",
                    "success"
                 )
                    // window.location.href = "/mod-groups";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    swal("Oops", error, "error")
                    // toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getStamp = (id: number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/stamp/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_STAMP,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editStamp = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        data.user_info = jwt_decode(token);
        Api.put(`/admin/edit_stamp/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch(stampList())
                    swal(
                        "Good job!",
                        "Stamp updated successfully",
                        "success"
                     )
                    // window.location.href = "/mod-groups";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    swal("Oops", error, "error")
                    // toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}