import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { Tab, Nav } from "react-bootstrap";
//** Import Image */

import { Sparklines, SparklinesLine } from "react-sparklines";
import Apexspline from "./Apexspline";
import ApexchartsNegPost from "./ApexNagetivePosative";
import ApexchartsNegPost1 from "./ApexNagetivePosative1";
import Chartcircle from "../Analytics/ApexChartcircle";
import {
  PieChartSOS,
  TopItemsList,
  getWidgetsData,
  recentOrders,
} from "./../../../../redux/actions/homeAction";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../../../../redux/selectors";
import { API_URL } from "../../../../client-config";
import SalesChartPie from "./pie";

const sampleData1 = [
  8, 7, 6, 3, 2, 4, 6, 8, 12, 6, 12, 13, 10, 18, 14, 24, 16, 12, 19, 21, 16, 14,
  24, 21, 13, 15, 27, 29, 21, 11, 14, 19, 21, 17,
];
const sampleData2 = [
  19, 21, 16, 14, 24, 21, 13, 15, 27, 29, 21, 11, 14, 19, 21, 17, 12, 6, 12, 13,
  10, 18, 14, 24, 16, 12, 8, 7, 6, 3, 2, 7, 6, 8,
];
const sampleData3 = [
  8, 7, 6, 3, 2, 4, 6, 8, 10, 6, 12, 15, 13, 15, 14, 13, 21, 11, 14, 10, 21, 10,
  13, 10, 12, 14, 16, 14, 12, 10, 9, 8, 4, 1,
];
const sampleData4 = [
  20, 18, 16, 12, 8, 10, 13, 15, 12, 6, 12, 13, 10, 18, 14, 16, 17, 15, 19, 16,
  16, 14, 18, 21, 13, 15, 18, 17, 21, 11, 14, 19, 21, 17,
];
const tabData = [
  {
    name: "Home",
    content: <ApexchartsNegPost1 />,
  },
  {
    name: "Profile",
    content: <ApexchartsNegPost />,
  },
  {
    name: "Contact",
    content: <ApexchartsNegPost />,
  },

  {
    name: "Message",
    content: <ApexchartsNegPost />,
  },
];

const Home = () => {
  const dispatch = useDispatch();
  const recentOrderState :any = useSelector(selectors.homeState);
  const widgetsDataState = useSelector(selectors.homeState);
  const topItemsListState = useSelector(selectors.homeState);
  const pieChartState = useSelector(selectors.homeState);

  useEffect(() => {
    dispatch(recentOrders(0));
    dispatch(getWidgetsData());
    dispatch(TopItemsList());
    dispatch(PieChartSOS());
  }, []);

  const roundNumberOrZero = (number: number) => {
    return !isNaN(number) ? Math.round(number) : 0;
  };

  const widgetsData = widgetsDataState.widgetsData[0];

  const totalSales = roundNumberOrZero(widgetsData?.totalSales);
  const dineinSales = roundNumberOrZero(widgetsData?.dineinSales);
  const pickupSales = roundNumberOrZero(widgetsData?.pickupSales);
  const totalOrders = roundNumberOrZero(widgetsData?.totalOrders);
  const processingOrders = roundNumberOrZero(widgetsData?.processingOrders);
  const completeOrders = roundNumberOrZero(widgetsData?.completeOrders);
  const cancelOrders = roundNumberOrZero(widgetsData?.cancelOrders);
  const dineinVat = roundNumberOrZero(widgetsData?.dineinVAT);
  const pickupVat = roundNumberOrZero(widgetsData?.pickupVAT);
  const totalVat = roundNumberOrZero(widgetsData?.totalVAT);

  const pickupSalesPie = roundNumberOrZero(pieChartState.pieChartSos[0]?.sales || 0);
  const dineinSalesPie = roundNumberOrZero(pieChartState.pieChartSos[1]?.sales || 0);

  return (
    <Fragment>
      <div className="row mt-4">
        {/* Total Sales */}
        <div className="col-sm-custom">
          <div className="card-widget overflow-hidden">
            <div className="card-header media border-0 pb-0">
              {/* <div className="media-body">
                <h2 className="text-black">
                  {totalSales}
                </h2>
                <p className="mb-0 text-black">Total Sales</p>
              </div> */}
              {/* <svg
                width="20"
                height="36"
                viewBox="0 0 20 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.08 24.36C19.08 25.64 18.76 26.8667 18.12 28.04C17.48 29.1867 16.5333 30.1467 15.28 30.92C14.0533 31.6933 12.5733 32.1333 10.84 32.24V35.48H8.68V32.24C6.25333 32.0267 4.28 31.2533 2.76 29.92C1.24 28.56 0.466667 26.84 0.44 24.76H4.32C4.42667 25.88 4.84 26.8533 5.56 27.68C6.30667 28.5067 7.34667 29.0267 8.68 29.24V19.24C6.89333 18.7867 5.45333 18.32 4.36 17.84C3.26667 17.36 2.33333 16.6133 1.56 15.6C0.786667 14.5867 0.4 13.2267 0.4 11.52C0.4 9.36 1.14667 7.57333 2.64 6.16C4.16 4.74666 6.17333 3.96 8.68 3.8V0.479998H10.84V3.8C13.1067 3.98667 14.9333 4.72 16.32 6C17.7067 7.25333 18.5067 8.89333 18.72 10.92H14.84C14.7067 9.98667 14.2933 9.14667 13.6 8.4C12.9067 7.62667 11.9867 7.12 10.84 6.88V16.64C12.6 17.0933 14.0267 17.56 15.12 18.04C16.24 18.4933 17.1733 19.2267 17.92 20.24C18.6933 21.2533 19.08 22.6267 19.08 24.36ZM4.12 11.32C4.12 12.6267 4.50667 13.6267 5.28 14.32C6.05333 15.0133 7.18667 15.5867 8.68 16.04V6.8C7.29333 6.93333 6.18667 7.38667 5.36 8.16C4.53333 8.90667 4.12 9.96 4.12 11.32ZM10.84 29.28C12.28 29.12 13.4 28.6 14.2 27.72C15.0267 26.84 15.44 25.7867 15.44 24.56C15.44 23.2533 15.04 22.2533 14.24 21.56C13.44 20.84 12.3067 20.2667 10.84 19.84V29.28Z"
                  fill="#EA4989"
                />
              </svg> */}
            </div>
            <div className="card-body pt-4 p-0">
              {/* Sparkline Chart  */}
              {/* <Sparklines data={sampleData4}>
                <SparklinesLine style={{ strokeWidth: 2 }} color="#2D5C48" />
              </Sparklines> */}
              {/* Sparkline Chart  End */}
            </div>
          </div>
        </div>
        {/* DineIn sales */}
        <div className="col-sm-custom">
          <div className="card-widget overflow-hidden">
            <div className="card-header media border-0 pb-0">
              <div className="media-body">
                {/* <h2 className="text-black">
                  {dineinSales}

                  <span className="text-success fs-14">+4%</span>
                </h2>
                <p className="mb-0 text-black">Dine In Sales</p> */}
              </div>
              {/* <svg
                width="36"
                height="28"
                viewBox="0 0 36 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.75 6.75H30.0064L30.2189 4.62238C30.2709 4.10111 30.2131 3.57473 30.0493 3.07716C29.8854 2.57959 29.6192 2.12186 29.2676 1.73348C28.9161 1.3451 28.4871 1.03468 28.0082 0.822231C27.5294 0.609781 27.0114 0.500013 26.4875 0.5H7.0125C6.48854 0.500041 5.9704 0.609864 5.49148 0.822391C5.01256 1.03492 4.58348 1.34543 4.23189 1.73392C3.88031 2.12241 3.61403 2.58026 3.45021 3.07795C3.28639 3.57564 3.22866 4.10214 3.28075 4.6235L5.2815 24.6224C5.31508 24.9222 5.38467 25.2168 5.48875 25.5H1.75C1.41848 25.5 1.10054 25.6317 0.866116 25.8661C0.631696 26.1005 0.5 26.4185 0.5 26.75C0.5 27.0815 0.631696 27.3995 0.866116 27.6339C1.10054 27.8683 1.41848 28 1.75 28H31.75C32.0815 28 32.3995 27.8683 32.6339 27.6339C32.8683 27.3995 33 27.0815 33 26.75C33 26.4185 32.8683 26.1005 32.6339 25.8661C32.3995 25.6317 32.0815 25.5 31.75 25.5H28.0115C28.1154 25.2172 28.1849 24.9229 28.2185 24.6235L28.881 18H31.75C32.7442 17.9989 33.6974 17.6035 34.4004 16.9004C35.1035 16.1974 35.4989 15.2442 35.5 14.25V10.5C35.4989 9.50577 35.1035 8.55258 34.4004 7.84956C33.6974 7.14653 32.7442 6.75109 31.75 6.75ZM9.0125 25.5C8.70243 25.501 8.40314 25.3862 8.17327 25.1782C7.9434 24.9701 7.79949 24.6836 7.76975 24.375L5.7685 4.37575C5.75109 4.20188 5.7703 4.0263 5.82488 3.86031C5.87946 3.69432 5.96821 3.5416 6.0854 3.412C6.2026 3.28239 6.34564 3.17877 6.50532 3.10781C6.665 3.03685 6.83777 3.00013 7.0125 3H26.4875C26.6622 3.00012 26.8349 3.03681 26.9945 3.10772C27.1541 3.17863 27.2972 3.28218 27.4143 3.4117C27.5315 3.54123 27.6203 3.69386 27.6749 3.85977C27.7295 4.02568 27.7488 4.20119 27.7315 4.375L25.7308 24.3762C25.7007 24.6848 25.5566 24.971 25.3267 25.1788C25.0967 25.3867 24.7975 25.5012 24.4875 25.5H9.0125ZM33 14.25C32.9998 14.5815 32.868 14.8993 32.6337 15.1337C32.3993 15.368 32.0815 15.4998 31.75 15.5H29.1311L29.7561 9.25H31.75C32.0815 9.2502 32.3993 9.38196 32.6337 9.61634C32.868 9.85071 32.9998 10.1685 33 10.5V14.25Z"
                  fill="#EA4989"
                />
              </svg> */}
            </div>
            <div className="card-body pt-4 p-0">
              {/* Sparkline Chart  */}
              {/* <Sparklines data={sampleData1}>
                <SparklinesLine style={{ strokeWidth: 2 }} color="#2D5C48" />
              </Sparklines> */}
              {/* Sparkline Chart End */}
            </div>
          </div>
        </div>
        {/* Pickup Sales */}
        <div className="col-sm-custom">
          <div className="card-widget overflow-hidden">
            <div className="card-header media border-0 pb-0">
              <div className="media-body">
                {/* <h2 className="text-black">
                  {pickupSales}
                  <span className="text-success fs-14">+2.7%</span>
                </h2> */}
                {/* <p className="mb-0 text-black">Pickup Sales</p> */}
              </div>
              {/* <svg
                width="32"
                height="31"
                viewBox="0 0 32 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 30.5H22.75C23.7442 30.4989 24.6974 30.1035 25.4004 29.4004C26.1035 28.6974 26.4989 27.7442 26.5 26.75V16.75C26.5 16.4185 26.3683 16.1005 26.1339 15.8661C25.8995 15.6317 25.5815 15.5 25.25 15.5C24.9185 15.5 24.6005 15.6317 24.3661 15.8661C24.1317 16.1005 24 16.4185 24 16.75V26.75C23.9997 27.0814 23.8679 27.3992 23.6336 27.6336C23.3992 27.8679 23.0814 27.9997 22.75 28H4C3.66857 27.9997 3.3508 27.8679 3.11645 27.6336C2.88209 27.3992 2.7503 27.0814 2.75 26.75V9.25C2.7503 8.91857 2.88209 8.6008 3.11645 8.36645C3.3508 8.13209 3.66857 8.0003 4 8H15.25C15.5815 8 15.8995 7.8683 16.1339 7.63388C16.3683 7.39946 16.5 7.08152 16.5 6.75C16.5 6.41848 16.3683 6.10054 16.1339 5.86612C15.8995 5.6317 15.5815 5.5 15.25 5.5H4C3.00577 5.50109 2.05258 5.89653 1.34956 6.59956C0.646531 7.30258 0.251092 8.25577 0.25 9.25V26.75C0.251092 27.7442 0.646531 28.6974 1.34956 29.4004C2.05258 30.1035 3.00577 30.4989 4 30.5Z"
                  fill="#EA4989"
                />
                <path
                  d="M25.25 0.5C24.0138 0.5 22.8055 0.866556 21.7777 1.55331C20.7498 2.24007 19.9488 3.21619 19.4757 4.35823C19.0027 5.50027 18.8789 6.75693 19.1201 7.96931C19.3612 9.1817 19.9565 10.2953 20.8306 11.1694C21.7046 12.0435 22.8183 12.6388 24.0307 12.8799C25.243 13.1211 26.4997 12.9973 27.6417 12.5242C28.7838 12.0512 29.7599 11.2501 30.4466 10.2223C31.1334 9.19451 31.5 7.98613 31.5 6.75C31.498 5.093 30.8389 3.50442 29.6672 2.33274C28.4955 1.16106 26.907 0.501952 25.25 0.5ZM25.25 10.5C24.5083 10.5 23.7833 10.2801 23.1666 9.86801C22.5499 9.45596 22.0692 8.87029 21.7854 8.18506C21.5016 7.49984 21.4273 6.74584 21.572 6.01841C21.7167 5.29098 22.0739 4.6228 22.5983 4.09835C23.1228 3.5739 23.7909 3.21675 24.5184 3.07206C25.2458 2.92736 25.9998 3.00162 26.685 3.28545C27.3702 3.56928 27.9559 4.04993 28.368 4.66661C28.78 5.2833 29 6.00832 29 6.75C28.9989 7.74423 28.6034 8.69742 27.9004 9.40044C27.1974 10.1035 26.2442 10.4989 25.25 10.5Z"
                  fill="#EA4989"
                />
                <path
                  d="M6.5 13H12.75C13.0815 13 13.3995 12.8683 13.6339 12.6339C13.8683 12.3995 14 12.0815 14 11.75C14 11.4185 13.8683 11.1005 13.6339 10.8661C13.3995 10.6317 13.0815 10.5 12.75 10.5H6.5C6.16848 10.5 5.85054 10.6317 5.61612 10.8661C5.3817 11.1005 5.25 11.4185 5.25 11.75C5.25 12.0815 5.3817 12.3995 5.61612 12.6339C5.85054 12.8683 6.16848 13 6.5 13Z"
                  fill="#EA4989"
                />
                <path
                  d="M5.25 16.75C5.25 17.0815 5.3817 17.3995 5.61612 17.6339C5.85054 17.8683 6.16848 18 6.5 18H17.75C18.0815 18 18.3995 17.8683 18.6339 17.6339C18.8683 17.3995 19 17.0815 19 16.75C19 16.4185 18.8683 16.1005 18.6339 15.8661C18.3995 15.6317 18.0815 15.5 17.75 15.5H6.5C6.16848 15.5 5.85054 15.6317 5.61612 15.8661C5.3817 16.1005 5.25 16.4185 5.25 16.75Z"
                  fill="#EA4989"
                />
              </svg> */}
            </div>
            {/* <div className="card-body pt-4 p-0">
              Sparkline Chart 
              <Sparklines data={sampleData2}>
                <SparklinesLine style={{ strokeWidth: 2 }} color="#2D5C48" />
              </Sparklines>
              Sparkline Chart End
            </div> */}
          </div>
        </div>
        {/* Total Orders */}
        <div className="col-sm-custom">
          <div className="card-widget overflow-hidden">
            <div className="card-header media border-0 pb-0">
              <div className="media-body">
                {/* <h2 className="text-black">
                  {totalOrders}
                  <span className="text-danger fs-14">-3.7%</span>
                </h2>
                <p className="mb-0 text-black">Total Orders</p> */}
              </div>


            </div>
            <div className="card-body pt-4 p-0">
              {/* Sparkline Chart 
              <Sparklines data={sampleData4}>
                <SparklinesLine style={{ strokeWidth: 2 }} color="#2D5C48" />
              </Sparklines>
              Sparkline Chart  End */}
            </div>
          </div>
        </div>
        
        <div className="col-sm-custom">
          <div className="card-widget overflow-hidden">
            <div className="card-header media border-0 pb-0">
              <div className="media-body">
                {/* <h2 className="text-black">
                  {totalOrders}
                  <span className="text-danger fs-14">-3.7%</span>
                </h2>
                <p className="mb-0 text-black">Total Orders</p> */}
              </div>


            </div>
            <div className="card-body pt-4 p-0">
              {/* Sparkline Chart 
              <Sparklines data={sampleData4}>
                <SparklinesLine style={{ strokeWidth: 2 }} color="#2D5C48" />
              </Sparklines>
              Sparkline Chart  End */}
            </div>
          </div>
        </div>
        </div>
        <div className="row">
        {/* Sales Summary Card  */}
        <div className="col-xl-8 col-lg-12">
          <div className="card">
            <div className="card-header border-0">
              <div>
                <h4 className="card-title mb-2">Sales Summary</h4>
                
              </div>
            </div>
            <div className="card-body">
              <div className="row mx-0 align-items-center">
                <div className="col-sm-4 col-md-5 px-0 text-center">
                  <div className="chart-deta d-inline-block">
                    <div className="col px-0">
                      
                    </div>
                    <div className="col px-0">
                      
                    </div>
                  </div>
                </div>
                {/* Apext Chart Circle */}

                  <div className="col-sm-8 col-md-7  px-0">
                    <div>
                      <SalesChartPie
                        color1="#2D5C48"
                        color3="#f35757"
                        color2="#ffb800"
                        salesData={pieChartState.pieChartSos}
                      />
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        
        
        <div className="col-xl-4 col-lg-12">
          
            <div className="card">
              <div className="card-header border-0">
                <div>
                  <h4 className="card-title mb-2">New Users</h4>
                  <p className="mb-0 fs-14">
                    
                  </p>
                </div>
              </div>
            </div>
        </div>

        <div className="col-xl-4 col-lg-12">
            <div className="card">
              <div className="card-header border-0">
                <div>
                  <h4 className="card-title mb-2">Order Summary</h4>
                  <p className="mb-0 fs-14">
                    No Order Summary.
                  </p>
                </div>
              </div>
            </div>
        </div>
        
        <div className="col-xl-4 col-lg-12">
            <div className="card">
              <div className="card-header border-0">
                <div>
                  <h4 className="card-title mb-2">Order by category</h4>
                  <p className="mb-0 fs-14">
                    No orders.
                  </p>
                </div>
              </div>
            </div>
        </div>
        
        {/* Daily Trending Menu Card  */}
        <div className="col-xl-4 col-lg-12">
          {!topItemsListState.topItemsList ||
          topItemsListState.topItemsList.length === 0 ? (
            <div className="card">
              <div className="card-header border-0">
                <div>
                  <h4 className="card-title mb-2">Daily Trending Menus</h4>
                  <p className="mb-0 fs-14">
                    No trending menus available at the moment.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="card-header border-0">
                <div>
                  <h4 className="card-title mb-2">Daily Trending Menus</h4>
                </div>
              </div>
              <div className="card-body px-0 pt-0 pb-2">
                <div className="widget-media trending-menus">
                  <ul className="timeline">
                    {topItemsListState.topItemsList.map(
                      (item: any, index: number) => (
                        <li key={index}>
                          <div className="timeline-panel">
                            <div className="media mr-3">
                              <Link to={"/ecom-product-detail"}>
                                <img
                                  alt=""
                                  width="90"
                                  src={`${API_URL}${item.image_url}`}
                                />
                              </Link>
                              {/* <div className="number">#1</div> */}
                            </div>
                            <div className="media-body">
                              <h5 className="mb-3">
                                <Link
                                  to={"/ecom-product-detail"}
                                  className="text-black"
                                >
                                  {item.item_name}
                                </Link>
                              </h5>
                              <div className="d-flex justify-content-between align-items-center">
                                <h4 className="mb-0 text-black font-w600">
                                  kr {item.mrp}
                                </h4>
                                {/* <p className="mb-0">
                            Order{" "}
                            <strong className="text-black font-w500">
                              89x
                            </strong>
                          </p> */}
                              </div>
                            </div>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>

        
        
        
        {/* Recent Orders Card  */}
        
        
        {/* <div className="col-xl-12 col-xxl-12 col-lg-12 col-sm-12">
          <div className="card">
            <div className="card-header border-0">
              <div>
                <h4 className="card-title mb-2">Recent Order Requests</h4>
              </div>
            </div>
            {recentOrderState.orderData.length === 0 ? (
              <div className="card-body p-0">
                <div className="table-responsive">
                  <p>No Recent Orders Available</p>
                </div>
              </div>
            ) : (
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table order-request wspace-no">
                    <thead>
                      <tr>
                        <th>Order ID</th>
                        <th>Name</th>
                        <th>Channel</th>
                        <th>Mode</th>
                        <th>Payment Method</th>
                        <th>Amount</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recentOrderState.orderData.map(
                        (item: any, index: any) => (
                          <tr key={index}>
                            <td>#{item.order_id}</td>
                            <td>{`${item.first_name} ${item.last_name}`}</td>
                            <td>{item.order_channel}</td>
                            <td>{item.delivery_status}</td>
                            <td>{item.payment_method}</td>

                            <td>
                              {"kr" +
                                " " +
                                Number(item.order_netprice).toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                            </td>

                            <td>
                              <div className="d-flex align-items-center">
                                <Link
                                  to={"#"}
                                  className="btn bgl-warning text-warning"
                                >
                                  {item.order_status_description}
                                </Link>
                                <Dropdown>
                                  <Dropdown.Toggle className="table-dropdown i-false">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <rect
                                          x="0"
                                          y="0"
                                          width="24"
                                          height="24"
                                        ></rect>
                                        <circle
                                          fill="#000000"
                                          cx="12"
                                          cy="5"
                                          r="2"
                                        ></circle>
                                        <circle
                                          fill="#000000"
                                          cx="12"
                                          cy="12"
                                          r="2"
                                        ></circle>
                                        <circle
                                          fill="#000000"
                                          cx="12"
                                          cy="19"
                                          r="2"
                                        ></circle>
                                      </g>
                                    </svg>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item>
                                      <Link to={"#"} className="text-black">
                                        <svg
                                          className="mr-3"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
                                            stroke="#209F84"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M22 4L12 14.01L9 11.01"
                                            stroke="#209F84"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                        Accept order
                                      </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      <Link to={"#"} className="text-black">
                                        <svg
                                          className="mr-3"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                            stroke="#F24242"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            d="M15 9L9 15"
                                            stroke="#F24242"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            d="M9 9L15 15"
                                            stroke="#F24242"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                        </svg>
                                        Reject order
                                      </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      <Link to={"#"} className="text-black">
                                        <svg
                                          className="mr-3"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                            stroke="#6F6F6F"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            d="M12 16V12"
                                            stroke="#6F6F6F"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                          <path
                                            d="M12 8H12.01"
                                            stroke="#6F6F6F"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></path>
                                        </svg>
                                        View Details
                                      </Link>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                  <div className="card-footer border-0 pt-0 text-center">
                    <Link to={"/order-list"} className="btn-link">
                      View More &gt;&gt;
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div> */}
      </div>
    </Fragment>
  );
};

export default Home;
