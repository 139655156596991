import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import Select from 'react-select';
import { AddModGroupProps, AddModGroupState } from '../../../interface/newMenu';
import { secretKey } from '../../../../secret';
import { addModGroup, groupsListForMultiSelect, itemsListForMultiSelect, logoutUser, menusList } from '../../../../redux';
import { Button, Modal, Nav, Tab } from 'react-bootstrap';
import { addChoice, addStamp } from '../../../../redux/actions/menuAction';
import { AddStampProps, AddStampState } from '../../../interface/stamp';
class AddStamp extends Component<AddStampProps, AddStampState> {
    constructor(props: any) {
        super(props);
        this.state = {
            itemsJson: {},
            groupJson: {},
            stamp_title:"",
            stamp_description: "",
            stamp_limit: 0,
            level: "group",
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleModifiersLevel = this.handleModifiersLevel.bind(this);
    }
    componentDidMount() {
        this.props.itemsListForMultiSelect();
        this.props.groupsList();
        document.title = "Add New Stamp"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleModifiersLevel(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    handleItemsInputChange = (e: any, index: any) => {
        if (e) 
        {
            this.setState({ itemsJson: e });
        }
    }
    isStampReady = () => {
        const {stamp_description,stamp_limit,itemsJson,groupJson} = this.state
        if(itemsJson){
            return (stamp_description !== "" && (itemsJson) && stamp_limit > 0);
        }
        if(groupJson){
            return (stamp_description !== "" && (groupJson) && stamp_limit > 0);
        }
    }
    handleSaveBtnClick = (event: any) => {
        const {stamp_description,stamp_limit,itemsJson,groupJson,level,stamp_title} = this.state
        let data: any = {
            stamp_title: stamp_title,
            stamp_description: stamp_description,
            stamp_limit: stamp_limit,
            type:level
        }
        if(JSON.stringify(itemsJson) !== "{}")
        {
            data["product"] = JSON.stringify(itemsJson);
        }
        if(JSON.stringify(groupJson) !== "{}")
        {
            data["category"] = JSON.stringify(groupJson);
        }
        console.log("data",data);
        this.props.addStamp(data);
        this.props.setStampModal(false);
    }
    handleGroupsInputChange = (e: any, index: any) => {
        if (e) {
            this.setState({ itemsJson: {}, groupJson: e });
        }
    };
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isInserted, message, items,groups } = this.props;
        if (!isInserted) {
            msg = message;
            messcolor = 'red'
        }
        return (
            <>    
                <Modal
                    className="fade bd-example-modal-lg"
                    show={this.props.stampModal}
                    size="lg"
                >    
                <Modal.Header>
                <Modal.Title>Add New Stamp</Modal.Title>
                    <Button
                    variant=""
                    className="close"
                    onClick={() => this.props.setStampModal(false)}
                    >
                    <span>&times;</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                <div className="row">
                    <div className="col-5">
                        <div className="form-group">
                            <label className="form-control-label">Stamp Title <span className="text-danger">*</span></label>
                            <input id="stamp_title" type="text" maxLength={20} name="stamp_title" required data-msg="Please enter stamp title" className="form-control" onChange={this.handleInputChange} />
                        </div>
                    </div>
                    <div className="col-5">
                        <div className="form-group">
                            <label className="form-control-label">Stamp Description <span className="text-danger">*</span></label>
                            <input id="stamp_description" maxLength={40} type="text" name="stamp_description" required data-msg="Please enter stamp title" className="form-control" onChange={this.handleInputChange} />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="form-group">
                            <label className="form-control-label">Stamp Limit<span className="text-danger">*</span></label>
                            <input id="stamp_limit" type="number" name="stamp_limit" required data-msg="Please enter stamp limit" className="form-control" onChange={this.handleInputChange} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                        <div className="col">
                                <div className="form-group">
                                    <label className="form-control-label">Stamp Level <span className="text-danger">*</span></label>
                                    <select name="level" className="form-control text-capitalize" required data-msg="Please select Type" onChange={this.handleModifiersLevel}>
                                        <option value='group'>Category</option>
                                        <option value='item'>Product</option>
                                    </select>
                                </div>
                        </div>
                        {
                            this.state.level === "group" &&
                                <div className="col-lg-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-control-label">Select Category <span className="text-danger">*</span></label>
                                        <Select
                                            // isMulti
                                            name="groups"
                                            options={groups}
                                            className="text-capitalize basic-multi-select mt-2"
                                            classNamePrefix="select"
                                            onChange={(e, i) => this.handleGroupsInputChange(e, i)}
                                        />
                                    </div>
                                </div>
                        }
                        {
                            this.state.level === "item" &&
                                <div className="col-lg-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-control-label">Select Product <span className="text-danger">*</span></label>
                                        <Select
                                            // isMulti
                                            name="items"
                                            options={items}
                                            className="text-capitalize basic-multi-select mt-2"
                                            classNamePrefix="select"
                                            onChange={(e, i) => this.handleItemsInputChange(e, i)}
                                        />
                                    </div>
                                </div>
                        }
                    </div>
                </Modal.Body>
                    
                <Modal.Footer>
                    <Button
                    variant="danger light"
                    onClick={() => this.props.setStampModal(false)}
                    >
                        Close
                    </Button>
                    <button className='btn btn-primary' disabled={!this.isStampReady()} onClick={this.handleSaveBtnClick}>Save</button>
                    
                    {/* <Button variant="" type="button" className="btn btn-primary">
                    Save Item
                    </Button> */}
                </Modal.Footer>
                </Modal>   
            </>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        items: state.menu.allActiveItems,
        message: state.menu.message,
        isInserted: state.menu.isInserted,
        groups: state.menu.groupsptions,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addStamp : function (data : any){
            dispatch(addStamp(data));
        },
        itemsListForMultiSelect: function () {
            dispatch(itemsListForMultiSelect())
        },
        groupsList: function () {
            dispatch(groupsListForMultiSelect())
        },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddStamp);